import { Group } from '@microsoft/microsoft-graph-types';

const mockGroups: Group[] = [
    {
        id: '8ad4178f-5f15-46e1-882e-9b645032ce20',
        displayName: 'Mentoring App UAT',
        mail: 'mpappuat@microsoft.com',
    },
    {
        id: '3be76a5d-cc33-4ed2-ae8a-cb4146f955fb',
        displayName: 'Mentoring Program 1',
        mail: 'mpg1@microsoft.com',
    },
    {
        id: '966a4b55-3540-4287-ab78-a7894f914f05',
        displayName: 'Mentoring Program 2',
        mail: 'mpg2@microsoft.com',
    },
    {
        id: '018886c5-0540-4fcc-adf2-7650a7c0af68',
        displayName: 'Mentoring Program 3',
        mail: 'mpg3@microsoft.com',
    },
];
export const findGroups = async () => {
    return Promise.resolve<Group[]>(mockGroups);
};

export const getGroupsForGroupIds = async (groupIds: string[]) => {
    const filteredGroups = mockGroups.filter((group) => groupIds.some((gid) => gid === group.id));
    return Promise.resolve<Group[]>(filteredGroups);
};
