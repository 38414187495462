import { NeutralColors } from '@cseo/styles';
import { IDropdownStyles } from 'office-ui-fabric-react';

const queryBuilderCustomBorderStyle: React.CSSProperties = {
    backgroundColor: 'unset',
    border: 0,
    borderBottom: `1px solid ${NeutralColors.gray60}`,
    borderRadius: 0,
};

export const logicOperatorStyles: Partial<IDropdownStyles> = {
    title: { ...(queryBuilderCustomBorderStyle as IDropdownStyles), borderBottom: 0 },
    root: { display: 'flex' },
};
