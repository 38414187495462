export enum FeatureName {
    TopNavBar = 'TopNavBar',
    UserLogin = 'UserLogin',
    Basics = 'Basics',
    Mentors = 'Mentors',
    Mentees = 'Mentees',
    Resources = 'Resources',
    Communication = 'Communication',
    AdminPortal = 'AdminPortal',
    Finish = 'Finish',
    Notification = 'Notification',
    OCV = 'OCV'
}

export enum SubFeatureName {
    //Common
    SaveAsDraft = 'SaveAsDraft',
    Continue = 'Continue',
    Back = 'Back',
    Submit = 'Submit',

    //TopNav
    Overview = 'Overview',
    Programs = 'Programs',
    Mentors = 'Mentors',
    Mentees = 'Mentees',
    AdminPortal = 'AdminPortal',

    //Admin portal
    NewProgram = 'NewProgram',
    Refresh = 'Refresh',
    ProgramDetails = 'ProgramDetails',
    EmailHistory = 'EmailHistory',

    //Basics
    MentoringTopics_SelectAllTopics = 'MentoringTopics.SelectAllTopics',
    MentoringTopics_AddTopic = 'MentoringTopics.AddTopic',
    UploadIcon = 'UploadIcon',

    //Mentors
    AdditionalRequirements_TrainingIds = 'AdditionalRequirements.TrainingIds',
    AdditionalRequirements_ClosedEnrollment = 'AdditionalRequirements.ClosedEnrollment',
    RecommendedCommitment_MaxMentees = 'RecommendedCommitment.MaxMentees',
    RecommendedCommitment_MeetingsCount = 'RecommendedCommitment.MeetingsCount',

    //Resources
    LinkToResources_AddResource = 'LinkToResources.AddResource',
    LinkToResources_RemoveResource = 'LinkToResources.RemoveResource',
    LinkToResources_ViewableBy = 'LinkToResources.ViewableBy',
    LinkToAdminDashboard_ViewReports = 'LinkToAdminDashboard.ViewReports',

    //Communication
    Communication_Save = 'Communication_Save',
    Communication_Cancel = 'Communication_Cancel',
    Communication_TemplateLink = 'Communication_TemplateLink',

    //Notification
    Notification_Save = 'Notification_Save',
    Notification_Cancel = 'Notification_Cancel',
    Notification_EditEmail = 'Notification_EditEmail',
    Notification_SendTestEmail = 'Notification_SendTestEmail',
    Notification_SendEmail = 'Notification_SendEmail',
    Notification_NotYet = 'Notification_NotYet',
    Notification_TemplateLink = 'Notification_TemplateLink',

    //Finish
    Edit = 'Edit',
}

export enum ActionName {
    Click = 'Click',
    Close = 'Close',
    Add = 'Add',
    Check = 'Check',
    Uncheck = 'Uncheck',
    DropDownSelect = 'DropDownSelect',
    Delete = 'Delete',
    None = 'None',
    Upload = 'Upload',
    FloodgateSurveySubmitted = 'FloodGateSurveySubmitted',
    FloodgateSurveyDismissed = 'FloodGateSurveryDismissed'
}

export enum TelemetryEvents {
    // App
    SessionStarted = 'SessionStarted',

    // User
    UserLogInRequested = 'UserLogInRequested',
    UserLoginFailed = 'UserLoginFailed',
    UserLogOutRequested = 'UserLogOutRequested',
    UserLogOutFailed = 'UserLogOutFailed',
    AcquireTokenFailed = 'AcquireTokenFailed',
}
