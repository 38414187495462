import { IStyle, mergeStyleSets } from 'office-ui-fabric-react';

const programTrackerPanelRoot: IStyle = {
    paddingTop: '48px',
    paddingLeft: '44px',
    paddingRight: '44px',
    borderRight: '1px solid #E1E1E1;',
    width: '248px',
    selectors: {
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
};
const programTrackerPanelCallOut: IStyle = {

    width: '240px',
    selectors: {
        '@media (min-width: 1024px)': {
            display: 'none',
        },
    },
};

const root: IStyle = {
    display: 'flex',
    height: '100%',
    backgroundColor: '#ffffff',
};

const spinner: IStyle = {
    width: '100%',
};

const upsertSpinner: IStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
};

const contentRoot: IStyle = {
    height: '100%',
    paddingTop: '48px',
    selectors: {
        '@media (min-width: 1024px)': {
            marginLeft: '50px',
        },
        '@media (min-width:640px) and (max-width: 1023px)': {
            margin: '0px 32px 0px 32px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            margin: '0px 24px 0px 24px',
            width: '100%',
        },
        '@media (max-width: 479px)': {
            margin: '0px 16px 0px 16px',
        },
    },
};

const messageBarContanier: IStyle = {
    paddingBottom: '10px',
};

export const getAdminProgramStyles = () => {
    return mergeStyleSets({
        root,
        contentRoot,
        programTrackerPanelRoot,
        spinner,
        upsertSpinner,
        messageBarContanier,
        programTrackerPanelCallOut,
    });
};
