import * as React from 'react';
import { getAccessDeniedStyles } from './AccessDenied.styles';
import { AccessDeniedImage } from './AccessDeniedImage';
import { AccessDeniedPageResources } from './resources';

export const AccessDenied = () => {
    const styles = getAccessDeniedStyles();
    return (
        <div className={styles.root}>
            <AccessDeniedImage />
            <div role="heading" aria-level={1} className={styles.header}>
                {AccessDeniedPageResources.AccessDeniedMessage}
            </div>
            <div>{AccessDeniedPageResources.MoreInformation} </div>
        </div>
    );
};
