import { useState, useEffect } from 'react';
import { useMentoringComponentContext } from './useMentoringComponentContext';

export const useIsUserInFeature = (feature: string) => {
    const [isUserInFeature, setIsUserInFeature] = useState(false);
    const { featureService } = useMentoringComponentContext();
    const checkFeatureAccess = async () => {
        if (await featureService.isFeatureEnabled(feature)) {
            setIsUserInFeature(true);
        }
    };
    useEffect(() => {
        checkFeatureAccess();
    });

    return isUserInFeature;
};
