import { MessagingColors, NeutralColors } from '@cseo/styles';
import { IBasePickerStyles, IButtonStyles, IStyle, mergeStyleSets } from 'office-ui-fabric-react';

export const warningIconButton: IButtonStyles = {
    icon: {
        color: MessagingColors.severeWarningIcon,
    },
};

export const successIconButton: IButtonStyles = {
    icon: {
        color: MessagingColors.successIcon,
    },
};

const root: IStyle = {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    gap: '12px',
    marginLeft: '10px',
    marginTop: '10px',
    marginRight: '10px',
};


export const peoplePickerWrapperStyles: Partial<IBasePickerStyles> = {
    text: {
        minWidth: '0px'
    },
    itemsWrapper: {
        '@media (min-width: 1366px)': {
            width: '240px'
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '174px'
        },
        '@media (max-width: 1023px)': {
            width: '150px'
        }
    }
};

const peoplePickerStyles: IStyle = {
    background: NeutralColors.white,
    borderColor: NeutralColors.black,
};

// TODO: need to revisit post availability of mocks.
const column: IStyle = {
    selectors: {
        '&:nth-child(2n-1)': {
            width: '25px',
        },
        '&:nth-child(2n)': {
            '@media (min-width: 1366px)': {
                width: '241px',
            },
            '@media (min-width: 1024px) and (max-width: 1365px)': {
                width: '175px',
            },
            '@media (max-width: 1023px)': {
                width: '150px',
            },
        },
        '&:nth-child(3n)': {
            '@media (min-width: 1366px)': {
                width: '120px',
            },
            '@media (min-width: 1024px) and (max-width: 1365px)': {
                width: '119px',
                marginLeft: '37px',
            },
            '@media (max-width: 1023px)': {
                width: '150px',
                marginLeft: '37px',
            },
        },
        '&:nth-child(4n)': {
            '@media (min-width: 1366px)': {
                width: '360px',
            },
            '@media (min-width: 1024px) and (max-width: 1365px)': {
                width: '240px',
                marginLeft: '37px',
            },
            '@media (max-width: 1023px)': {
                width: '150px',
                marginLeft: '37px',
            },
        },
        '&:nth-child(5n)': {
            width: '64px',
            marginLeft: '25px',
            marginRight: '-25px',
        },
    },
};
export const getConditionComposerStyles = () => {
    return mergeStyleSets({
        root,
        column,
        peoplePicker: peoplePickerStyles,
    });
};
