export enum IProgramStepState {
    Active,
    Error,
    Completed,
    Pending,
}

export enum IProgramState {
    Basics = 'Basics',
    Mentors = 'Mentors',
    Mentees = 'Mentees',
    PairingCriteria = 'PairingCriteria',
    Resources = 'Resources',
    Communications = 'Communications',
    Finish = 'Finish',
}

export interface IProgramTrackerStepProps {
    displayName: string;
    state: IProgramStepState;
    index?: number;
}

export interface IProgramTrackerProps {
    currentProgramState: IProgramState;
    isInErrorState: boolean;
}


