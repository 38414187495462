export enum ActionType {
    User = 'User',
    System = 'System',
    Aware = 'Aware',
}
export enum ComponentType {
    Web = 0,
    SmartApp = 1,
    Device = 2,
    BackgroundProcess = 3,
    WebService = 4,
    Executable = 5,
    DynamicLinkLibrary = 6,
    JobService = 7,
    WorkflowComponent = 8,
    DataStore = 9,
    Other = 10,
}
export enum EventName {
    PageLoad = 'PageLoad',
    DropdownSelected = 'DropdownSelected',
    DropdownChange = 'DropdownChange',
    PageNavigation = 'PageNavigation',
    CheckboxChange = 'CheckboxChange',
    ListChange = 'ListChange',
    ButtonClick = 'ButtonClick',
    LinkClick = 'LinkClick',
    TabChange = 'TabChange',
    TabNavigation = 'TabNavigation',
    PanelOpened = 'PanelOpened',
    PanelClosed = 'PanelClosed',
    PickerSelected = 'PickerSelected',
    PickerDeselected = 'PickerDeselected',
    TileAdded = 'TileAdded',
    TileClicked = 'TileClicked',
    TileDeleted = 'TileDeleted',
    TileLoaded = 'TileLoaded',
    OnFocus = 'OnFocus',
    OnBlur = 'OnBlur',
    TextChange = 'TextChange',
    Scroll = 'Scroll',
    SectionRendering = 'SectionRendering',
    BackEndAPICall = 'BackEndAPICall',
    Rendering = 'Rendering',
    NA = 'NA',
    Hover = 'Hover',
    LinkClicked = 'LinkClicked',
}
