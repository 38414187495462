import { Icon, IStackTokens, Stack } from 'office-ui-fabric-react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import * as React from 'react';
import { logicOperatorStyles } from './ConditionGroupOperatorSelector.styles';
import { ConditionGroupResources } from './resources';
import { LogicalOperator } from '../../../../Shared';

export const stackChildrenGapTokens: IStackTokens = { childrenGap: 12 };

export const ConditionGroupOperatorSelector = (props: {
    operator?: LogicalOperator;
    isAtRootComposer?: boolean;
    onOperatorChange: (selectedKey: LogicalOperator) => void;
}) => {
    return (
        <Dropdown
            placeholder="Select a logic operator"
            options={ConditionGroupResources?.getRuleSetOperators}
            selectedKey={props?.operator}
            onChange={(_, option) => {
                props?.onOperatorChange(option.key as LogicalOperator);
            }}
            styles={logicOperatorStyles}
            ariaLabel="Combobox"
            onRenderTitle={(dropdownOptions: IDropdownOption[]): JSX.Element => {
                return (
                    <Stack horizontal tokens={stackChildrenGapTokens} verticalAlign={'center'}>
                        <Icon iconName={'GroupList'} />
                        <Stack.Item>
                            {ConditionGroupResources.groupOperatorDescription({
                                prefix:
                                    props?.isAtRootComposer === true
                                        ? ConditionGroupResources.match
                                        : ConditionGroupResources.JoinTypeDescriptions[props?.operator],
                                operatorDesc: dropdownOptions[0].text,
                            })}
                        </Stack.Item>
                    </Stack>
                );
            }}
        />
    );
};
