import * as React from 'react';
import { Stack, TextField, Link, ITextField } from '@fluentui/react';
import { EmailTemplateResources, mapTemplateURL } from '../resources';
import { getEmailTemplateStyles, textFieldStyle } from './EmailTemplate.styles';
import { CommunicationTemplate, AudienceEnum } from '../../../Shared/Api/sdk.types';
const styles = getEmailTemplateStyles();
const stackTokens = { childrenGap: 50 };

interface IEmailTemplateProps {
  editMode: boolean,
  template: CommunicationTemplate,
  onParameterChange: (newTemplate: CommunicationTemplate) => void,
  onTemplateLinkClick: () => void,
}

export const EmailTemplate = (props: IEmailTemplateProps) => {
  const [template, setTemplate] = React.useState<CommunicationTemplate>(props.template);
  let textFieldRef: React.RefObject<ITextField>[] = [];
  React.useEffect(() => {
    setTemplate(props.template)
  }, [props.template])

  React.useEffect(() => {
    if(props.editMode === true && textFieldRef.length > 0 ){
      textFieldRef[0]?.current?.focus();
    }
  }, [props.editMode])

  textFieldRef = template.stringParameters?.map(() => React.createRef<ITextField>());

  const urlParameter: string = mapTemplateURL[template.templateId] || "";

  const handleStringParametersChange = async (value: string, index: number) => {
    let newTemplate: CommunicationTemplate = {};
    newTemplate = Object.assign({}, template);
    let newStringParamenters = [...newTemplate.stringParameters];
    newStringParamenters[index] = { ...newStringParamenters[index], value: value };
    newTemplate.stringParameters = newStringParamenters;
    setTemplate(newTemplate);
    props.onParameterChange(newTemplate);
  };

  const { audience, triggerType } = props.template;
  return (
    <div>
      <div className={styles.invitePotentialMenteesSize}>
        {EmailTemplateResources.InvitePotentialMentees}
      </div>
      <Stack tokens={stackTokens}>
        <div className={styles.audienceTriggerContainer}>
          <TextField label={EmailTemplateResources.AudienceLabel} disabled defaultValue={audience} className={styles.column} styles={textFieldStyle} />
          <TextField label={EmailTemplateResources.TriggerLabel} disabled defaultValue={triggerType} className={styles.column} styles={textFieldStyle} />
        </div>
      </Stack>
      <div role="heading" aria-level={2} className={styles.header}>
        {EmailTemplateResources.dynamicParametersHeader}
      </div>
      {template.stringParameters?.map((parameter, index) => {
        return <TextField label={parameter.description}
          componentRef={textFieldRef[index]}
          value={parameter.value}
          defaultValue={parameter.value}
          styles={textFieldStyle}
          multiline={true}
          onChange={(event) => handleStringParametersChange(event?.target?.value, index)}
          disabled={!props.editMode} />
      })}
      <div className={styles.viewTemplateLinkContainer}>
          {EmailTemplateResources.previewTemplate}
          <Link
              href={`https://emails.azure.net/preview/?container=&directory=&culture=en-us&templatePath=HR%2FCareerDevelopment%2F${urlParameter }%2F${urlParameter}`}
              role="link"
              target="_blank"
              onClick={props.onTemplateLinkClick}
            >
                {EmailTemplateResources.templatLinkText}
            </Link>
      </div>
    </div>
  );
}