import { Announced, MessageBarType, Overlay, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingStates } from '../../../Shared';
import { getAdminProgramStyles } from './CreateProgram.styles';
import { ProgramBasicsForm } from '../ProgramBasicsForm/ProgramBasicsForm';
import { ProgramFinishForm } from '../ProgramFinishForm/ProgramFinishForm';
import { ProgramMenteesForm } from '../ProgramMenteesForm/ProgramMenteesForm';
import { ProgramMentorsForm } from '../ProgramMentorsForm/ProgramMentorsForm';
import { ProgramResourcesForm } from '../ProgramResourcesForm/ProgramResourcesForm';
import { ProgramTracker } from '../ProgramTracker/ProgramTracker';
import { IProgramState } from '../ProgramTracker/ProgramTracker.types';
import { ProgramCommonResources } from '../resources';
import { ProgramProposal } from '../ProgramProposal/ProgramProposalModal';
import { useMentoringComponentContext } from '../../../Shared/Hooks/useMentoringComponentContext';
import { FeatureName, SubFeatureName } from '../../../Shared/Resources/TelemetryEvents';
import { useUser } from '@employee-experience/common';
import { isUserInAnyRole } from '../../../Shared/Hooks/useUser';
import { UserRoles } from '../../../Shared/Resources/Roles';
import { StatusMessageBar } from '../../../Shared/StatusMessageBar/StatusMessageBar';
import {
    CommunicationTemplates,
    DocumentStatusEnum,
    MentoringProgram,
    ProgramDocumentBase,
} from '../../../Shared/Api/sdk.types';
import { CommunicationNotifications } from '../ProgramCommunicationsForm/CommunicationNotifications';
import { useProgramStateSequenceLookUp } from '../../../Shared/Hooks/useProgramStateSequenceLookUp';

type adminProgramParams = {
    id?: string;
};

interface AdminProgramProps extends RouteComponentProps<adminProgramParams> {}

interface AdminProgramViewState {
    loadingState: LoadingStates;
    upsertState: LoadingStates;
    program: MentoringProgram;
    currentProgramState: IProgramState;
    showSubmitConfirmationModal?: boolean;
}

export const AdminProgram = (props: AdminProgramProps) => {
    const styles = getAdminProgramStyles();
    const [adminProgramViewState, setAdminProgramViewState] = useState({
        loadingState: LoadingStates.STARTED,
        program: null,
        upsertState: LoadingStates.NOT_STARTED,
        currentProgramState: IProgramState.Basics,
    } as AdminProgramViewState);
    const ProgramStateSequenceLookUp = useProgramStateSequenceLookUp();
    const [isInErrorState, setIsInErrorState] = React.useState(false);
    const { programService, appInsightsClient } = useMentoringComponentContext();
    const user = useUser();
    const isSuperAdmin = isUserInAnyRole([UserRoles.SuperAdmin]);
    const [announced, setAnnounced] = React.useState<JSX.Element | undefined>(undefined);

    React.useEffect(() => {
        const fetchMentoringProgram = async () => {
            try {
                if (props?.match?.params?.id) {
                    const program = await programService.getMentoringProgram(props?.match?.params?.id);
                    // if current logged in user is not super admin and also not in owners list of current program, redirect user to forbidden page.
                    if (isSuperAdmin === false && program.programDetail?.owners.includes(user.oid) === false) {
                        props?.history?.replace('/accessdenied');
                        return;
                    }
                    setAdminProgramViewState({
                        ...adminProgramViewState,
                        loadingState: LoadingStates.SUCCEEDED,
                        program,
                    });
                } else {
                    setAdminProgramViewState({
                        ...adminProgramViewState,
                        loadingState: LoadingStates.SUCCEEDED,
                        program: null,
                    });
                }
            } catch (error) {
                appInsightsClient.logError('PROGRAM_LOAD_FAILED', {
                    programid: props?.match?.params?.id,
                    error,
                });
                setAdminProgramViewState({
                    ...adminProgramViewState,
                    loadingState: LoadingStates.FAILED,
                    program: null,
                });
            }
        };
        if (user) {
            fetchMentoringProgram();
        }
    }, [user]);

    const setNextState = () => {
        appInsightsClient.logButtonClick(adminProgramViewState?.currentProgramState, SubFeatureName.Continue);
        const currentProgramStateIndex = ProgramStateSequenceLookUp.indexOf(
            adminProgramViewState?.currentProgramState as IProgramState
        );

        if (currentProgramStateIndex == ProgramStateSequenceLookUp.length - 1) {
            return;
        }
        setAdminProgramViewState({
            ...adminProgramViewState,
            currentProgramState: ProgramStateSequenceLookUp[currentProgramStateIndex + 1],
        });

        setIsInErrorState(false);
    };

    const setPreviousState = () => {
        appInsightsClient.logButtonClick(adminProgramViewState?.currentProgramState, SubFeatureName.Back);
        const currentProgramStateIndex = ProgramStateSequenceLookUp.indexOf(
            adminProgramViewState?.currentProgramState as IProgramState
        );

        if (currentProgramStateIndex == 0) {
            return;
        }
        setAdminProgramViewState({
            ...adminProgramViewState,
            currentProgramState: ProgramStateSequenceLookUp[currentProgramStateIndex - 1],
        });

        setIsInErrorState(false);
    };

    const updateProgramIdToRoute = (programId: string) => {
        if (props?.match?.params?.id == null || props?.match?.params?.id === undefined) {
            props?.history?.replace(`/programs/${programId}`);
        }
    };

    const onSaveOrDraft = async (programSection: any) => {
        // setting it to complete always once any program content is saved. Need to revisit and remove this overall
        // if document status is overhead toi maintain for each section.
        (programSection as ProgramDocumentBase).documentStatus = DocumentStatusEnum.Complete;
        setIsInErrorState(false);
        setAdminProgramViewState({
            ...adminProgramViewState,
            upsertState: LoadingStates?.STARTED,
        });
        appInsightsClient.logButtonClick(adminProgramViewState?.currentProgramState, SubFeatureName.SaveAsDraft);
        try {
            switch (adminProgramViewState?.currentProgramState) {
                case IProgramState?.Basics:
                    const programDetail = await programService.upsertProgramBasics(programSection);
                    updateProgramIdToRoute(programDetail?.programId);
                    setAdminProgramViewState({
                        ...adminProgramViewState,
                        program: { ...adminProgramViewState?.program, programDetail },
                        upsertState: LoadingStates.SUCCEEDED,
                    });
                    break;
                case IProgramState?.Mentors:
                    await programService.upsertMentorTargetingProfile(programSection);
                    setAdminProgramViewState({
                        ...adminProgramViewState,
                        program: { ...adminProgramViewState?.program, mentorTargetingProfile: programSection },
                        upsertState: LoadingStates.SUCCEEDED,
                    });
                    break;
                case IProgramState?.Mentees:
                    await programService.upsertMenteeTargetingProfile(programSection);
                    setAdminProgramViewState({
                        ...adminProgramViewState,
                        program: { ...adminProgramViewState?.program, menteeTargetingProfile: programSection },
                        upsertState: LoadingStates.SUCCEEDED,
                    });
                    break;
                case IProgramState?.Resources:
                    await programService.upsertProgramResources(programSection);
                    setAdminProgramViewState({
                        ...adminProgramViewState,
                        program: { ...adminProgramViewState?.program, programResources: programSection },
                        upsertState: LoadingStates.SUCCEEDED,
                    });
                    break;
                case IProgramState?.Communications:
                    await programService.upsertCommunicationTemplates(programSection as CommunicationTemplates);
                    setAdminProgramViewState({
                        ...adminProgramViewState,
                        program: {
                            ...adminProgramViewState?.program,
                            communicationTemplates: programSection as CommunicationTemplates,
                        },
                        upsertState: LoadingStates.SUCCEEDED,
                    });
                    break;
            }
        } catch (error) {
            appInsightsClient.logError('PROGRAM_CREATE_OR_UPDATE_FAILED', {
                programid: props?.match?.params?.id,
                section: adminProgramViewState?.currentProgramState,
                error,
            });
            setAdminProgramViewState({
                ...adminProgramViewState,
                upsertState: LoadingStates?.FAILED,
            });
        }
    };

    const onSubmit = async () => {
        appInsightsClient.logButtonClick(adminProgramViewState?.currentProgramState, SubFeatureName.Submit);
        setAdminProgramViewState({
            ...adminProgramViewState,
            upsertState: LoadingStates?.STARTED,
        });
        try {
            await programService.submitProgramToPendingState(adminProgramViewState?.program?.programDetail?.programId);
            setAdminProgramViewState({
                ...adminProgramViewState,
                upsertState: LoadingStates?.SUCCEEDED,
                showSubmitConfirmationModal: true,
            });
        } catch (error) {
            appInsightsClient.logError('PROGRAM_SUBMIT_FAILED', {
                programid: props?.match?.params?.id,
                error,
            });
            setAdminProgramViewState({
                ...adminProgramViewState,
                upsertState: LoadingStates?.FAILED,
            });
        }
    };

    // TODO : can be moved below function to its own component.
    const programStateView = (programState: IProgramState) => {
        if (programState === IProgramState.Basics) {
            return (
                <ProgramBasicsForm
                    programBasicDetails={adminProgramViewState?.program?.programDetail}
                    onContinueOrFinish={() => {
                        setNextState();
                    }}
                    onSaveAsDraft={(basics) => onSaveOrDraft(basics)}
                    onPostValidation={(hasErrors) => setIsInErrorState(hasErrors)}
                />
            );
        } else if (programState === IProgramState.Mentees) {
            return (
                <ProgramMenteesForm
                    programMenteeTargetingProfile={adminProgramViewState?.program?.menteeTargetingProfile}
                    programId={adminProgramViewState?.program?.programDetail?.programId}
                    onContinueOrFinish={() => {
                        setNextState();
                    }}
                    onPostValidation={(hasErrors) => setIsInErrorState(hasErrors)}
                    onSaveAsDraft={(menteeTargetingProfile) => onSaveOrDraft(menteeTargetingProfile)}
                    onBack={() => {
                        setPreviousState();
                    }}
                />
            );
        } else if (programState === IProgramState.Mentors) {
            return (
                <ProgramMentorsForm
                    onPostValidation={(hasErrors) => setIsInErrorState(hasErrors)}
                    programMentorTargetingProfile={adminProgramViewState?.program?.mentorTargetingProfile}
                    programId={adminProgramViewState?.program?.programDetail?.programId}
                    onContinueOrFinish={() => {
                        setNextState();
                    }}
                    onSaveAsDraft={(mentorTargetingProfile) => onSaveOrDraft(mentorTargetingProfile)}
                    onBack={() => {
                        setPreviousState();
                    }}
                />
            );
        } else if (programState === IProgramState.Resources) {
            return (
                <ProgramResourcesForm
                    onPostValidation={(hasErrors) => setIsInErrorState(hasErrors)}
                    programResources={adminProgramViewState?.program?.programResources}
                    programId={adminProgramViewState?.program?.programDetail?.programId}
                    onContinueOrFinish={() => {
                        setNextState();
                    }}
                    onSaveAsDraft={(programResources) => onSaveOrDraft(programResources)}
                    onBack={() => {
                        setPreviousState();
                    }}
                />
            );
        } else if (programState === IProgramState.Communications) {
            return (
                <CommunicationNotifications
                    programId={adminProgramViewState?.program?.programDetail?.programId}
                    communicationTempates={adminProgramViewState?.program?.communicationTemplates}
                    programResources={adminProgramViewState?.program?.programResources}
                    onContinueOrFinish={() => {
                        setNextState();
                    }}
                    onSaveAsDraft={(templates: CommunicationTemplates) => onSaveOrDraft(templates)}
                    onBack={() => {
                        setPreviousState();
                    }}
                />
            );
        } else if (programState === IProgramState.Finish) {
            return (
                <ProgramFinishForm
                    programId={adminProgramViewState?.program?.programDetail?.programId}
                    program={adminProgramViewState?.program}
                    onContinueOrFinish={() => {
                        onSubmit();
                    }}
                    onSaveAsDraft={() => onSaveOrDraft({} as any)}
                    onBack={() => {
                        setPreviousState();
                    }}
                    onEditProgram={(programState) => {
                        appInsightsClient.logButtonClick(FeatureName.Finish, SubFeatureName.Edit + ' ' + programState);
                        setAdminProgramViewState({
                            ...adminProgramViewState,
                            currentProgramState: programState,
                        });
                    }}
                />
            );
        }
    };

    if (adminProgramViewState?.loadingState === LoadingStates.STARTED) {
        return (
            <div className={styles.root}>
                <Spinner
                    className={styles.spinner}
                    label="Loading..."
                    ariaLive="polite"
                    labelPosition="right"
                    size={SpinnerSize.small}
                />
            </div>
        );
    }
    if (adminProgramViewState?.loadingState === LoadingStates.SUCCEEDED) {
        return (
            <>
                <Announced message={adminProgramViewState?.currentProgramState + ' section'} aria-live="assertive" />
                <div
                    className={styles.root}
                    onLoad={() =>
                        setAnnounced(
                            <Announced
                                message={adminProgramViewState?.currentProgramState + ' section'}
                                aria-live="assertive"
                            />
                        )
                    }
                >
                    {announced}
                    <div className={styles.programTrackerPanelRoot}>
                        <ProgramTracker
                            currentProgramState={adminProgramViewState?.currentProgramState}
                            isInErrorState={isInErrorState}
                        />
                    </div>
                    <div className={styles.contentRoot}>
                        {adminProgramViewState?.upsertState === LoadingStates.FAILED && (
                            <div className={styles.messageBarContanier}>
                                <StatusMessageBar
                                    message={ProgramCommonResources.SaveFailureMessage}
                                    messageBarType={MessageBarType.error}
                                ></StatusMessageBar>
                            </div>
                        )}

                        {adminProgramViewState?.upsertState === LoadingStates.SUCCEEDED && (
                            <div className={styles.messageBarContanier}>
                                <StatusMessageBar
                                    message={ProgramCommonResources.SaveSuccessMessage}
                                    messageBarType={MessageBarType.success}
                                    autoHideInMillSec={3000}
                                ></StatusMessageBar>
                            </div>
                        )}
                        {programStateView(adminProgramViewState?.currentProgramState)}
                    </div>
                    {adminProgramViewState?.upsertState === LoadingStates?.STARTED && (
                        <Overlay>
                            <Spinner
                                className={styles.upsertSpinner}
                                label="Saving..."
                                ariaLive="polite"
                                labelPosition="right"
                                size={SpinnerSize.small}
                            />
                        </Overlay>
                    )}
                    <ProgramProposal
                        isModalOpen={adminProgramViewState?.showSubmitConfirmationModal}
                        onDismiss={() => {
                            props?.history?.push('../');
                        }}
                        programName={adminProgramViewState?.program?.programDetail?.name}
                    />
                </div>
            </>
        );
    }

    // need to replace with right error view.
    return (
        <div className={styles.root}>
            <h1 style={{ color: 'red' }}>Something went wrong. Please try again later</h1>
        </div>
    );
};
