import * as React from 'react';
import { useState } from 'react';
import { LevelOneItem } from './LevelOneItem';

export const HierarchyList = React.forwardRef(
    (
        props: { source: ParentItem[]; onChange: (modifiedChild: ChildItem) => void },
        ref: React.Ref<HierarchyListRef>
    ) => {
        const [hierarchyList, setHierarchyList] = useState(props?.source);

        const onChange = (modifiedChild: ChildItem, parentIndex?: number) => {
            updateItem(modifiedChild, parentIndex);
            props?.onChange(modifiedChild);
        };

        const updateItem = (modifiedChild: ChildItem, parentIndex?: number) => {
            parentIndex = parentIndex || hierarchyList.findIndex((p) => p.id === modifiedChild.parentId);
            const childIndex = hierarchyList[parentIndex].children.findIndex((item) => item.id === modifiedChild.id);
            hierarchyList[parentIndex] = { ...hierarchyList[parentIndex] };
            hierarchyList[parentIndex].children[childIndex] = modifiedChild;
            setHierarchyList([...hierarchyList]);
        };

        React.useImperativeHandle(ref, () => ({
            clearAll: () => {
                const updatedList = hierarchyList.map((p) => {
                    p.children = p.children.map((c) => {
                        if (c.selected) {
                            return { ...c, selected: false };
                        }
                        return c;
                    });
                    return p;
                });
                setHierarchyList(updatedList);
            },
            clearItemSelection: (modifiedChild: ChildItem) => {
                updateItem({ ...modifiedChild, selected: false });
            },
        }));

        return (
            <>
                {hierarchyList?.map((parent, parentIndex) => {
                    return (
                        <LevelOneItem
                            source={parent}
                            key={`${parent.id}-${parentIndex}`}
                            onChange={(modifiedChild) => onChange(modifiedChild, parentIndex)}
                        />
                    );
                })}
            </>
        );
    }
);
