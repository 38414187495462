import { IStyle, mergeStyleSets, FontWeights } from "office-ui-fabric-react";

const dateError: IStyle = {
    fontSize: "12px",
    color : "#A80000"
}

const endLabel: IStyle = {
    padding: "5px 0px 6px 0px",
    fontWeight: FontWeights.semibold,
}

const startLabel: IStyle = {
    fontWeight: FontWeights.semibold,
}

export const getFullDatePickerStyles = ()=> {
    return mergeStyleSets({    
        dateError,
        startLabel,
        endLabel
    });
};