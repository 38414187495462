import * as React from 'react';
import { useState, useEffect } from 'react';
import { CompletedIcon, ErrorBadgeIcon, RecentIcon } from '@fluentui/react-icons-mdl2';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { useMentoringComponentContext } from '../../../Shared/Hooks/useMentoringComponentContext';
import { EmailHistoryPageResources } from '../resources';
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsRowProps,
    DetailsRowGlobalClassNames,
    IDetailsListProps,
    Persona,
} from '@fluentui/react';
import { PersonaSize, SelectionMode } from 'office-ui-fabric-react';
import { FeatureName, SubFeatureName } from '../../../Shared/Resources/TelemetryEvents';
import { EmailHistoryFilterRequest, EmailTypeEnum, LoadingStates } from '../../../Shared';

interface EmailHistoryClientProps {
    programId: string;
}

export const EmailHistory = (props: EmailHistoryClientProps) => {
    const [items, setItems] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const { appInsightsClient, emailHistoryService, graphService } = useMentoringComponentContext();
    const programId = props?.programId;
    const [sortDesc, setSortDesc] = useState<boolean>(true);
    const [emailTypes] = useState<EmailTypeEnum[]>([EmailTypeEnum.Invitation, EmailTypeEnum.MenteeOneMonthCheckIn]);
    const [loadingState, setLoadingState] = React.useState(LoadingStates.NOT_STARTED);
    let emailHistoryFilterRequest: EmailHistoryFilterRequest = { emailTypes: emailTypes };

    useEffect(() => {
        setLoadingState(LoadingStates.STARTED);
        emailHistoryService
            .getFilteredEmailHistory(programId, emailHistoryFilterRequest)
            .then((data) => {
                let _items: any[] = [];
                data.forEach((item) => {
                    _items.push({
                        audience: item.audience,
                        emailName: item.emailType,
                        status:
                            item.status == EmailHistoryPageResources.emailStatusSending ? (
                                <div>
                                    <RecentIcon />
                                    &nbsp;{item.status}
                                </div>
                            ) : item.status == EmailHistoryPageResources.emailStatusFailed ? (
                                <div>
                                    <ErrorBadgeIcon />
                                    &nbsp;{item.status + '. Please Try Again'}
                                </div>
                            ) : (
                                <div>
                                    <CompletedIcon />
                                    &nbsp;{item.status + ' ' + item.totalEmail + '/' + item.totalEmail}
                                </div>
                            ),
                        requestDate: new Date(item.requestDate).toLocaleString('en-US'),
                        requestedBy: (
                            <div>
                                <Persona
                                    imageUrl={graphService.getPhotoForResource(item.submitter).toString()}
                                    size={PersonaSize.size24}
                                    text={item.submitter}
                                />
                            </div>
                        ),
                    });
                });
                setItems(_items);
                setLoadingState(LoadingStates.SUCCEEDED);
            })
            .catch((error) => {
                appInsightsClient.logError(error, { emailHistory: 'Call to getFilteredEmailHistory method failed.' });
                setErrorMessage(error);
                setLoadingState(LoadingStates.FAILED);
            });
    }, []);

    const onHeaderColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const order = !sortDesc;
        setSortDesc(order);
        const sortedItems = _copyAndSort(items, column.fieldName!, order);
        setItems(sortedItems);

        const telemetryProperties = {
            columnName: column,
            sortOrder: order,
        };

        appInsightsClient.logButtonClick(FeatureName.AdminPortal, SubFeatureName.EmailHistory, telemetryProperties);
    };

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const rowStyles: IDetailsRowProps['styles'] = (props) => {
        const { palette } = props.theme!;
        return {
            root: {
                '&:hover': {
                    background: palette.white,
                    color: palette.neutralSecondary,
                    [`.${DetailsRowGlobalClassNames.isRowHeader}`]: {
                        color: palette.neutralPrimary,
                    },
                },
            },
        };
    };
    const onRenderRow: IDetailsListProps['onRenderRow'] = (props, defaultRender) => {
        return defaultRender!({ ...props!, styles: rowStyles });
    };

    let _columns: IColumn[] = [
        {
            key: 'emailName',
            name: EmailHistoryPageResources.emailHistoryTableFields.emailName,
            fieldName: 'emailName',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: sortDesc,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: onHeaderColumnClick,
        },
        {
            key: 'status',
            name: EmailHistoryPageResources.emailHistoryTableFields.status,
            fieldName: 'status',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: sortDesc,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: onHeaderColumnClick,
        },
        {
            key: 'requestDate',
            name: EmailHistoryPageResources.emailHistoryTableFields.requestDate,
            fieldName: 'requestDate',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: sortDesc,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: onHeaderColumnClick,
        },
        {
            key: 'audience',
            name: EmailHistoryPageResources.emailHistoryTableFields.audience,
            fieldName: 'audience',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: sortDesc,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: onHeaderColumnClick,
        },
        {
            key: 'requestedBy',
            name: EmailHistoryPageResources.emailHistoryTableFields.requestedBy,
            fieldName: 'requestedBy',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: sortDesc,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: onHeaderColumnClick,
        },
    ];

    if (loadingState === LoadingStates.STARTED) {
        return <Spinner label="Loading..." ariaLive="polite" labelPosition="right" size={SpinnerSize.medium} />;
    }

    if (loadingState === LoadingStates.SUCCEEDED && items?.length > 0) {
        return (
            <div>
                <br />
                <DetailsList
                    columns={_columns}
                    items={items}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    onRenderRow={onRenderRow}
                />
            </div>
        );
    } else if (loadingState === LoadingStates.SUCCEEDED && ((items === null ) || (items?.length == 0)) ) {
        return (
            <div>
                <br />
                <h3>No Email History Data Available</h3>
            </div>
        );
    } else {
        return (
            <div>
                <br />
                <h4>{errorMessage}</h4>
            </div>
        );
    }
};
