import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActionButton, IStackTokens, MessageBarType, Pivot, PivotItem, Stack } from '@fluentui/react';
import { useMentoringComponentContext } from '../../../Shared/Hooks/useMentoringComponentContext';
import { MentoringProgram, CommunicationTemplate, TriggerTypeEnum, TemplateParameterOfString, TemplateStateEnum } from '../../../Shared/Api/sdk.types';
import { isEqual } from '../../../Shared/Utilities';
import { getProgramNotificationStyle, programNotificationContainer } from './ProgramNotifications.styles'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Accordion } from '../../../Shared/Components/Accordion/Accordion';
import { ProgramNotification } from './ProgramNotification'
import { EmailTemplateResources, mapTemplateCallOut } from '../resources';
import { EmailHistory } from './EmailHistory';
import { FeatureName, SubFeatureName } from '../../../Shared/Resources/TelemetryEvents';

interface ProgramNotificationsProps extends RouteComponentProps<any> { }

interface ProgramNotificationsViewState {
    communicationTemplates: CommunicationTemplate[];
}

export const ProgramNotifications = (props: ProgramNotificationsProps) => {
    const [mentoringProgram, setMentoringProgram] = useState<MentoringProgram>(undefined);
    const [programNotificationsViewState, setprogramNotificationsViewState] = useState({
        communicationTemplates: [],
    } as ProgramNotificationsViewState);
    const styles = getProgramNotificationStyle();
    const containerToken: IStackTokens = { padding: 50 };
    const id = props?.match?.params?.id;
    const { programService, appInsightsClient } = useMentoringComponentContext();
    const history = useHistory();
    let counter = 0;

    useEffect(() => {
        const fetchMentoringProgram = async () => {
            const program: MentoringProgram = await programService.getMentoringProgram(id);
            setMentoringProgram(program);
            // save initial state of communication templates
            setprogramNotificationsViewState({
                ...programNotificationsViewState,
                communicationTemplates: program.communicationTemplates.templates,
            });
        }
        if (!mentoringProgram && id) {
            fetchMentoringProgram();
        }
    }, []);

    const onDynamicParametersChange = (newTemplate: CommunicationTemplate, index: number) => {
        let currentMentoringProgram: MentoringProgram = {};
        currentMentoringProgram = Object.assign({}, mentoringProgram);
        const { communicationTemplates: { templates } } = currentMentoringProgram;
        let currentTemplates = templates ? [...templates] : [];
        currentTemplates[index] = newTemplate;
        currentMentoringProgram.communicationTemplates.templates = currentTemplates;
        setMentoringProgram(currentMentoringProgram)
    }

    const onSaveClick = async (templateId: string) => {
        appInsightsClient.logButtonClick(
            FeatureName.Notification,
            SubFeatureName.Notification_Save,
            { templateId: templateId, programId: id }
        );
        await programService.upsertCommunicationTemplates(mentoringProgram.communicationTemplates);
        // reset initial state of communication templates
        setprogramNotificationsViewState({
            ...programNotificationsViewState,
            communicationTemplates: mentoringProgram.communicationTemplates.templates,
        });
    }

    const onCancelClick = async (templateId: string) => {
        appInsightsClient.logButtonClick(
            FeatureName.Notification,
            SubFeatureName.Notification_Cancel,
            { templateId: templateId, programId: id }
        );
        let currentMentoringProgram: MentoringProgram = {};
        currentMentoringProgram = Object.assign({}, mentoringProgram);
        currentMentoringProgram.communicationTemplates.templates = programNotificationsViewState.communicationTemplates;
        setMentoringProgram(currentMentoringProgram);
    }

    return (
        <Stack tokens={containerToken}>
            <Stack className={styles.backToPortal} horizontal verticalAlign="center">
                <ActionButton
                    iconProps={{
                        iconName: "ChevronLeft"
                    }}
                    onClick={() => { history?.push(''); }}
                >
                    {EmailTemplateResources.backToPortal}
                </ActionButton>
            </Stack>
            <h1 className={styles.communicationHeader}>{mentoringProgram?.programDetail?.name || ""}</h1>
            <Pivot aria-label={EmailTemplateResources.emailNotification} className={styles.pivot}>
                <PivotItem
                    headerText={EmailTemplateResources.communication}
                >
                    <Stack>
                        <div style={programNotificationContainer}>
                            {mentoringProgram?.communicationTemplates?.templates?.filter(t => t.state as TemplateStateEnum === "Active").map(
                                (template: CommunicationTemplate, index: number) => {

                                    if (template.triggerType == TriggerTypeEnum.Manual) {
                                        counter++;
                                        return (<Accordion header={template.description} shouldShowContent={counter == 1} callOutContent={mapTemplateCallOut[template.templateId]}>
                                            <ProgramNotification
                                                template={template}
                                                programId={id}
                                                index={index}
                                                originalTemplate={programNotificationsViewState.communicationTemplates[index]}
                                                onParameterChange={(newTemplate: CommunicationTemplate) => onDynamicParametersChange(newTemplate, index)}
                                                onSaveClick={() => onSaveClick(template.templateId)}
                                                onCancelled={() => onCancelClick(template.templateId)}
                                                checkForChanges={!isEqual(programNotificationsViewState.communicationTemplates[index], template)}
                                            />
                                        </Accordion>
                                        )
                                    }
                                }
                            )}
                        </div>
                    </Stack>
                </PivotItem>
                <PivotItem headerText={EmailTemplateResources.emailHistory} aria-label={EmailTemplateResources.emailHistory}>
                    <EmailHistory
                        programId={id}
                    />
                </PivotItem>
            </Pivot>
        </Stack>
    );
}