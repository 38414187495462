import { ProgramAudiencePreviewClient, ProgramServiceModuleApiException } from '../Api/sdk.types';

export class ProgramAudiencePreviewService {
    private readonly programAudiencePreviewClient: ProgramAudiencePreviewClient;
    public constructor(programAudiencePreviewClient: ProgramAudiencePreviewClient) {
        this.programAudiencePreviewClient = programAudiencePreviewClient;
    }
    public getAudiencePreview = async (versionId: string) => {
        try {
            return await this.programAudiencePreviewClient.getAudiencePreview(versionId);
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    };

    public generateAudiencePreview = async (versionId: string) => {
        try {
            return await this.programAudiencePreviewClient.generateAudiencePreview(versionId);
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    };
}
