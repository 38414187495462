import { FieldOperator, LogicalOperator, PropertyTypeEnum } from '../../../../Shared';
import { template } from '../../../../Shared/Utilities';

export const FieldOperatorOptions = {
    InOption: { key: FieldOperator.In, text: 'In' },
    NotInOption: { key: FieldOperator.NotIn, text: 'Not In' },
    EqualOption: { key: FieldOperator.Equals, text: '=' },
    NotEqual: { key: FieldOperator.NotEquals, text: '!=' },
    UnderOption: { key: FieldOperator.In, text: 'Under' },
};

export const ConditionGroupResources = {
    eligibileCriteriaFieldOptions: [
        { key: PropertyTypeEnum.MemberOfGroups, text: 'Security Group' },
        { key: PropertyTypeEnum.Profession, text: 'Profession' },
        { key: PropertyTypeEnum.Discipline, text: 'Discipline' },
        { key: PropertyTypeEnum.RTDChain, text: 'Organization' },
        { key: PropertyTypeEnum.CareerStage, text: 'Career Stage' },
        { key: PropertyTypeEnum.City, text: 'City' },
        { key: PropertyTypeEnum.Country, text: 'Country' },
        { key: PropertyTypeEnum.Region, text: 'Region' },
    ],
    PropertyTypeOperatorOptionMapping: {
        [PropertyTypeEnum.RTDChain]: { placeHolder: 'under', options: [FieldOperatorOptions.UnderOption] },
        [PropertyTypeEnum.MemberOfGroups]: {
            placeHolder: 'In, Not In',
            options: [FieldOperatorOptions.InOption, FieldOperatorOptions.NotInOption],
        },
        [PropertyTypeEnum.Profession]: {
            placeHolder: 'In, Not In',
            options: [FieldOperatorOptions.InOption, FieldOperatorOptions.NotInOption],
        },
        [PropertyTypeEnum.City]: {
            placeHolder: 'In, Not In',
            options: [FieldOperatorOptions.InOption, FieldOperatorOptions.NotInOption],
        },
        [PropertyTypeEnum.Region]: {
            placeHolder: 'In, Not In',
            options: [FieldOperatorOptions.InOption, FieldOperatorOptions.NotInOption],
        },
        [PropertyTypeEnum.Country]: {
            placeHolder: 'In, Not In',
            options: [FieldOperatorOptions.InOption, FieldOperatorOptions.NotInOption],
        },
        [PropertyTypeEnum.Discipline]: {
            placeHolder: 'In, Not In',
            options: [FieldOperatorOptions.InOption, FieldOperatorOptions.NotInOption],
        },
        [PropertyTypeEnum.CareerStage]: {
            placeHolder: 'In, Not In',
            options: [FieldOperatorOptions.InOption, FieldOperatorOptions.NotInOption],
        },
    },
    ariaLabelPropertyFieldPlaceHolder: 'Dropdown for Select attributes',
    propertyFieldPlaceHolder: ' Select attributes',
    andOperatorText: 'and',
    orOperatorText: 'or',
    match: 'Match',
    groupOperatorDescription: template`${'prefix'} when ${'operatorDesc'} of the following are true`,
    ariaLabelForselectValues: 'Dropdown Select Values',
    selectValues: 'Select Values',
    ariaLabeloperatorPlaceholder: 'Dropdown Select',
    operatorPlaceholder: 'Select',
    getRuleSetOperators: [
        {
            key: 'And',
            text: 'all',
        },
        {
            key: 'Or',
            text: 'any',
        },
    ],
    JoinTypeDescriptions: {
        [LogicalOperator.And]: 'and',
        [LogicalOperator.Or]: 'or',
    },
};

export const ConditionComposerResources = {
    errorMessage: {
        InvalidOrganizationLeader: 'Invalid organization Leader',
        InvalidSecurityGroup: 'Invalid security group',
        ValueCannotBeBlank: 'Value cannot be blank',
        FieldCannotBeBlank: 'Field cannot be blank',
        OperatorCannotBeBlank: 'Operator cannot be blank',
    },
};
