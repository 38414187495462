import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { getProgramTrackerStepStyles } from './ProgramTracker.styles';
import { IProgramStepState, IProgramTrackerStepProps } from './ProgramTracker.types';

export const ProgramTrackerStep = (props: IProgramTrackerStepProps) => {
    const styles = getProgramTrackerStepStyles(props);
    return (
        <>
            <div className={styles.stepContainer}>
                <div className={styles.step}>
                    {props?.state === IProgramStepState.Completed && (
                        <Icon className={styles.stepIcon} iconName="CheckMark" />
                    )}
                    {props?.state === IProgramStepState.Error && (
                        <Icon className={styles.stepIcon} iconName="StatusCircleErrorX" />
                    )}
                </div>
                <div role= 'menuitem' className={styles.stepLabel} aria-label={`${props?.index + 1} ${props?.displayName}`}>{props?.displayName}</div>
            </div>
            <div className={styles.connector}></div>
        </>
    );
};
