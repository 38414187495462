import { UsageUserServiceModule } from '@common-services/sdk';
import { IConfig } from '../models/IConfig';
export class UsageUserService {
    private readonly usageUserClient: UsageUserServiceModule.UsageUserClient;
    public constructor(config: IConfig, baseUrl: string) {
        this.usageUserClient = new UsageUserServiceModule.UsageUserClient(config, baseUrl);
    }

    /**
     * Get AnonymizedId for User.
     */
    public async getAnonymizedUserId(): Promise<string> {
        var anonymousId = '';
        try {
            anonymousId = await this.usageUserClient.getMyUsageUserId();
        } catch (err) {
            console.log(err);
        }
        return anonymousId;
    }
}
