import { FontSizes, FontWeights, IStyle, mergeStyleSets } from 'office-ui-fabric-react';

const contentRoot: IStyle = { display: 'flex', flexDirection: 'column' };
const childListWrapper: IStyle = { ...contentRoot, marginLeft: '30px', rowGap: '5px' };
const headerRoot: IStyle = { marginLeft: '48px', marginRight: '48px' };
const headerLabel: IStyle = { fontWeight: FontWeights.bold, fontSize: FontSizes.size32 };
const headerContent: IStyle = {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '15px',
    marginBottom: '15px',
};
const selectdCountLabel: IStyle = { fontWeight: FontWeights.bold };
export const buttonStyles = { root: { marginRight: 8 } };

export const getHierarchicalDataPickerStyles = () => {
    return mergeStyleSets({ contentRoot, childListWrapper, headerRoot, headerLabel, headerContent, selectdCountLabel });
};
