import { IStyle, mergeStyleSets } from "office-ui-fabric-react";

const submitForReviewButton: IStyle = {

}

const modalContainer: IStyle = {
    height: 597,
    width: 809,
    overflow: "hidden",
}

const programProposalContainer: IStyle = {
    height: 457,
    width: 540,
    display: "flex",
    alignItems: "center",
    marginLeft: 130,
    marginTop: 100
}

const checkMark: IStyle = {
    marginTop: 50,
    marginBottom: 50,
    fontSize: 100,
    color: "#107C10"
}

const header: IStyle = {
    fontSize: 26,
    fontWeight: "700",
}

const body: IStyle = {
    fontSize: 16,
    color: "#646464",
    marginTop: 10,
    marginLeft: 96
}

const bodySecondary: IStyle = {
    fontSize: 16,
    color: '#646464',
    marginTop: 10,
    justifyContent: "center"
}

const thanksButton: IStyle = {
    display: "flex",
    alignitems: "center",
    justifyContent: "center",
    top: 75
}

export const getProgramProposalStyles = () => {
    return mergeStyleSets({
        submitForReviewButton,
        modalContainer,
        programProposalContainer,
        checkMark,
        header,
        body,
        bodySecondary,
        thanksButton
    });
};
