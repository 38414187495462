import { IStyle, mergeStyleSets } from 'office-ui-fabric-react';

const root: IStyle = {
    paddingTop: '12px',
    paddingBottom: '32px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '16px',
    marginBottom: 0,
};

const groupCommandBar: IStyle = {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    flexWrap: 'wrap',
};

const commandBar: IStyle = {
    marginLeft: '-8px',
};

export const getConditionGroupComposerStyles = (isRootComposer?: boolean) => {
    return mergeStyleSets({
        root: [root, { background: isRootComposer === true ? '#ebebeb' : '#ffffff' }],
        groupCommandBar,
        commandBar,
    });
};
