import { ProgramClient } from '../Api/sdk.types';
import { IFeatureFlightClient } from './interfaces/IFeatureFlightClient';
export class IFeatureFlight {
    [key: string]: boolean;
}

/**
 * Implementation for feature flight client.
 */
export class FeatureFlightClient implements IFeatureFlightClient {
    private readonly programClient: ProgramClient;
    private featureList: IFeatureFlight = {};
    constructor(programClient: ProgramClient) {
        this.programClient = programClient;
        programClient
            .getFlights()
            .then((value) => {
                this.featureList = value;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    async isFeatureEnabled(feature: string): Promise<boolean> {
        if (Object.keys(this.featureList) == null || Object.keys(this.featureList).length == 0) {
            this.featureList = await this.programClient.getFlights();
        }
        return this.featureList[feature];
    }
}
