import { User, Person, Group } from '@microsoft/microsoft-graph-types';
import * as graph from '@microsoft/microsoft-graph-client';
import { GroupType, UserType } from '../Models/Graph.types';
import { ResponseType } from '@microsoft/microsoft-graph-client';

// TODO: revisit on error handling and telemetry

export class GraphService {
    private readonly graphClient: graph.Client;

    public constructor(graphClient: graph.Client) {
        this.graphClient = graphClient;
    }

    public async findUsers(query: string, top: number = 10): Promise<User[]> {
        let graphResult = null;
        let filter = "userType eq 'Member' and endsWith(mail,'@microsoft.com') and NOT startsWith(mail, 'sc-')";
        try {
            graphResult = await this.graphClient
                .api('users')
                .header('ConsistencyLevel', 'eventual')
                .count(true)
                .filter(filter)
                .search(`"displayName:${query}" OR "mail:${query}" OR "mailNickname:${query}"`)
                .top(top)
                .get();
        } catch {}

        return graphResult ? graphResult.value : null;
    }

    public async findPeople(query: string, top: number = 10, userType: UserType = UserType.user): Promise<Person[]> {
        let filter = "personType/class eq 'Person'";
        if (userType !== UserType.any) {
            if (userType === UserType.user) {
                filter += "and personType/subclass eq 'OrganizationUser'";
            } else {
                filter += "and (personType/subclass eq 'ImplicitContact' or personType/subclass eq 'PersonalContact')";
            }
        }

        let graphResult = null;
        try {
            graphResult = await this.graphClient
                .api('/me/people')
                .search('"' + query + '"')
                .top(top)
                .filter(filter)
                .get();
            return graphResult ? graphResult.value : null;
        } catch {}
        return graphResult ? graphResult.value : null;
    }

    public async findGroups(query: string, top: number = 10, groupTypes: GroupType = GroupType.any): Promise<Group[]> {
        let filterQuery = '';
        if (query !== '') {
            filterQuery = `(startswith(displayName,'${query}') or startswith(mailNickname,'${query}') or startswith(mail,'${query}'))`;
        }

        if (groupTypes !== GroupType.any) {
            const filterGroups = [];

            if (GroupType.unified === (groupTypes & GroupType.unified)) {
                filterGroups.push("groupTypes/any(c:c+eq+'Unified')");
            }

            if (GroupType.security === (groupTypes & GroupType.security)) {
                filterGroups.push('(mailEnabled eq false and securityEnabled eq true)');
            }

            if (GroupType.mailenabledsecurity === (groupTypes & GroupType.mailenabledsecurity)) {
                filterGroups.push('(mailEnabled eq true and securityEnabled eq true)');
            }

            if (GroupType.distribution === (groupTypes & GroupType.distribution)) {
                filterGroups.push('(mailEnabled eq true and securityEnabled eq false)');
            }

            filterQuery += (query !== '' ? ' and ' : '') + filterGroups.join(' or ');
        }

        let graphResult = null;
        try {
            graphResult = await this.graphClient.api('groups').filter(filterQuery).top(top).get();
            return graphResult ? graphResult.value : null;
        } catch {}
        return graphResult ? graphResult.value : null;
    }

    public async getUsersForUserIds(userIds: string[]): Promise<User[]> {
        if (!userIds || userIds.length === 0) {
            return [];
        }

        let users: User[] = [];
        let requestSteps: graph.BatchRequestStep[] = [];
        for (let index = 0; index < userIds.length; index++) {
            let userRequestStep: graph.BatchRequestStep = {
                id: userIds[index],
                request: new Request(`/users?$filter=id eq '${userIds[index]}'`, {
                    method: 'GET',
                }),
            };
            requestSteps.push(userRequestStep);
        }
        let batchRequestContent = new graph.BatchRequestContent(requestSteps);

        let content = await batchRequestContent.getContent();

        // POST the batch request content to the /$batch endpoint
        let batchResponse = await this.graphClient.api('/$batch').post(content);

        let batchResponseContent = new graph.BatchResponseContent(batchResponse);

        for (let index = 0; index < userIds.length; index++) {
            // Get the user response using the id assigned to the request
            let userResponse = batchResponseContent.getResponseById(userIds[index]);

            // For a single entity, the JSON payload can be deserialized
            // into the expected type
            // Types supplied by @microsoft/microsoft-graph-types
            if (userResponse.ok) {
                let user = await userResponse.json();
                if (user.value && user.value?.length > 0) users.push(user.value[0]);
            } else {
                console.log(`Get user failed with status ${userResponse.status}`);
            }
        }
        return users;
    }

    public async getGroupsForGroupIds(groupIds: string[]): Promise<Group[]> {
        if (!groupIds || groupIds.length === 0) {
            return [];
        }

        let groups: Group[] = [];
        let requestSteps: graph.BatchRequestStep[] = [];
        for (let index = 0; index < groupIds.length; index++) {
            let userRequestStep: graph.BatchRequestStep = {
                id: groupIds[index],
                request: new Request(`/groups?$filter=id eq '${groupIds[index]}'`, {
                    method: 'GET',
                }),
            };
            requestSteps.push(userRequestStep);
        }
        let batchRequestContent = new graph.BatchRequestContent(requestSteps);

        let content = await batchRequestContent.getContent();

        // POST the batch request content to the /$batch endpoint
        let batchResponse = await this.graphClient.api('/$batch').post(content);

        let batchResponseContent = new graph.BatchResponseContent(batchResponse);

        for (let index = 0; index < groupIds.length; index++) {
            // Get the user response using the id assigned to the request
            try {
                let groupResponse = batchResponseContent.getResponseById(groupIds[index]);
                if (groupResponse.ok) {
                    let response = await groupResponse.json();
                    if (response && response.value && Array.isArray(response.value)) {
                        let groupArrayValue: [Group] = response.value;
                        groups.push(...groupArrayValue);
                    } else {
                        // For a single entity, the JSON payload can be deserialized
                        // into the expected type
                        // Types supplied by @microsoft/microsoft-graph-types
                        let group: Group = response;
                        groups.push(group);
                    }
                } else {
                    console.log(`Get user failed with status ${groupResponse.status}`);
                }
            } catch (error) {
                console.log(error);
            }
        }
        return groups;
    }

    public async getPhotoForResource(userId: string): Promise<string> {
        try {
            const response = (await this.graphClient
                .api(`users/${userId}/photo/$value`)
                .responseType(ResponseType.RAW)
                .get()) as Response;

            if (!response.ok) {
                return null;
            }
            const blob = await this.blobToBase64(await response.blob());
            return blob;
        } catch (e) {
            return null;
        }
    }
    private async blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = (_) => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });
    }
}
