import { IStyle, mergeStyleSets } from "office-ui-fabric-react";

const dateErrorStyle: IStyle = {
    fontSize: "12px",
    color : "#A80000"
}

export const getPeoplePickerStyles = ()=> {
    return mergeStyleSets({    
        dateErrorStyle
    });
};
