import { CommandButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { getHierarchicalDataPickerStyles } from './HierarchicalDataPicker.styles';
import { LevelTwoItem } from './LevelTwoItem';

export const LevelOneItem = (props: {
    source: ParentItem;
    onChange?: (modifiedChild: ChildItem, childIndex?: number) => void;
}) => {
    const { source } = props;
    const [expand, setExpand] = React.useState(false);
    const styles = getHierarchicalDataPickerStyles();

    return (
        <div>
            <CommandButton
                iconProps={{ iconName: expand === true ? 'ChevronUp' : 'ChevronDown' }}
                onClick={() => setExpand((prevExpand) => !prevExpand)}
            >
                {`${source.name} (${source.children?.length})`}
            </CommandButton>
            {expand === true && (
                <div className={styles.childListWrapper}>
                    {source?.children?.map((child, childIndex) => {
                        return (
                            <LevelTwoItem
                                source={child}
                                onChange={(modifiedChild) => props?.onChange(modifiedChild)}
                                key={`${source.id}-${child.id}-${childIndex}`}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};
