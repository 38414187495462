import { LoadingStates } from '../../../Shared/Models/LoadingStates';

import {
    IFetchMyProgramsAction,
    IFetchMyProgramsFailed,
    IFetchMyProgramsSuccess,
    ProgramActionType,
    MentoringTopicsActionType,
    IFetchMyMentoringTopicsSuccess
} from './ProgramList.actions';

import { IMentoringAppState } from '../../../App.types';
import { ProgramsListReducerName } from '../resources';
import { IProgramCard } from './ProgramsList.types';
import { IMentoringTopicsModel } from '../../../Shared/Api/sdk.types';

export interface IProgramsListState {
    mentoringTopics: IMentoringTopicsModel | null;
    programs: IProgramCard[] | null;
    programFetchOrUpdateStatus: LoadingStates;
}

export const programsListInitialState: IProgramsListState = {
    mentoringTopics: null,
    programs: null,
    programFetchOrUpdateStatus: LoadingStates.NOT_STARTED,
};

export const programsListStateSelector = (state: IMentoringAppState) =>
    state[ProgramsListReducerName] ?? programsListInitialState;

export function programsListReducer(
    prev: IProgramsListState = programsListInitialState,
    action: IFetchMyProgramsAction | IFetchMyProgramsSuccess | IFetchMyProgramsFailed
): IProgramsListState {
    switch (action.type) {
        case ProgramActionType.FETCH_MY_PROGRAMS:
            return {
                ...prev,
                programFetchOrUpdateStatus: LoadingStates.STARTED,
            };
        case ProgramActionType.FETCH_MY_PROGRAM_SUCCESS:
            const fetchProgramMentorSuccessAction: IFetchMyProgramsSuccess = action as IFetchMyProgramsSuccess;
            return {
                ...prev,
                programs: fetchProgramMentorSuccessAction.payload,
                programFetchOrUpdateStatus: LoadingStates.SUCCEEDED,
            };
        case ProgramActionType.FETCH_MY_PROGRAMS_FAILED:
            return {
                ...prev,
                programFetchOrUpdateStatus: LoadingStates.FAILED,
            };

        case MentoringTopicsActionType.FETCH_MENTORING_TOPICS:
            return {
                ...prev,
                programFetchOrUpdateStatus: LoadingStates.STARTED,
            };
        case MentoringTopicsActionType.FETCH_MENTORING_TOPICS_SUCCESS:
            const fetchMentoringTopicsSuccessAction: IFetchMyMentoringTopicsSuccess = action as IFetchMyMentoringTopicsSuccess;
            return {
                ...prev,
                mentoringTopics: fetchMentoringTopicsSuccessAction.payload,
                programFetchOrUpdateStatus: LoadingStates.SUCCEEDED,
            };
        case MentoringTopicsActionType.FETCH_MENTORING_TOPICS_FAILED:
            return {
                ...prev,
                programFetchOrUpdateStatus: LoadingStates.FAILED,
            };
        default:
            return prev;
    }
}
