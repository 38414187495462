import { ResponsiveMode, useResponsiveMode, useTheme } from '@fluentui/react';
import React, { useRef } from 'react';

export const AskHrImage = () => {
  const imgRef = useRef()
  const rmode = useResponsiveMode(imgRef)
  const theme = useTheme()
  // On Overflow, The AskHR image is not visible on white background hence adding responsive hook to decide on the fill color.
  let fillColor = "white"
  if(rmode == ResponsiveMode.small || rmode == ResponsiveMode.medium){
    fillColor = theme.palette.neutralSecondary    
  }

  return (
    <div style={{marginTop: '6px'}} ref={imgRef}>
      <svg width="54" height="18" viewBox="0 0 54 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.2627 13H7.47852L6.59668 10.5049H2.74121L1.89355 13H0.116211L3.78711 3.19727H5.61914L9.2627 13ZM6.16602 9.17871L4.80566 5.26855C4.76465 5.14095 4.72135 4.93587 4.67578 4.65332H4.64844C4.60742 4.91309 4.56185 5.11816 4.51172 5.26855L3.16504 9.17871H6.16602Z" fill={fillColor}/>
      <path d="M10.001 12.7812V11.3115C10.5934 11.7627 11.2474 11.9883 11.9629 11.9883C12.9199 11.9883 13.3984 11.7057 13.3984 11.1406C13.3984 10.9811 13.3574 10.8467 13.2754 10.7373C13.1934 10.6234 13.0817 10.5231 12.9404 10.4365C12.8037 10.3499 12.6396 10.2725 12.4482 10.2041C12.2614 10.1357 12.0518 10.0583 11.8193 9.97168C11.5322 9.85775 11.2725 9.73698 11.04 9.60938C10.8122 9.48177 10.6208 9.33822 10.4658 9.17871C10.3154 9.01465 10.2015 8.83008 10.124 8.625C10.0465 8.41992 10.0078 8.18066 10.0078 7.90723C10.0078 7.56999 10.0876 7.27376 10.2471 7.01855C10.4066 6.75879 10.6208 6.54232 10.8896 6.36914C11.1585 6.19141 11.4639 6.05924 11.8057 5.97266C12.1475 5.88151 12.5007 5.83594 12.8652 5.83594C13.5124 5.83594 14.0911 5.93392 14.6016 6.12988V7.51758C14.1094 7.18034 13.5443 7.01172 12.9062 7.01172C12.7057 7.01172 12.5234 7.03223 12.3594 7.07324C12.1999 7.11426 12.0632 7.17122 11.9492 7.24414C11.8353 7.31706 11.7464 7.40592 11.6826 7.51074C11.6188 7.611 11.5869 7.72266 11.5869 7.8457C11.5869 7.99609 11.6188 8.1237 11.6826 8.22852C11.7464 8.33333 11.8398 8.42676 11.9629 8.50879C12.0905 8.58626 12.2409 8.65918 12.4141 8.72754C12.5918 8.79134 12.7946 8.86198 13.0225 8.93945C13.3232 9.0625 13.5921 9.18783 13.8291 9.31543C14.0706 9.44303 14.2757 9.58887 14.4443 9.75293C14.613 9.91243 14.7428 10.0993 14.834 10.3135C14.9251 10.5231 14.9707 10.7738 14.9707 11.0654C14.9707 11.4209 14.8887 11.7308 14.7246 11.9951C14.5605 12.2594 14.3418 12.4782 14.0684 12.6514C13.7949 12.8245 13.4782 12.9521 13.1182 13.0342C12.7627 13.1208 12.3867 13.1641 11.9902 13.1641C11.2246 13.1641 10.5615 13.0365 10.001 12.7812Z" fill={fillColor}/>
      <path d="M22.832 13H20.8223L18.1016 9.63672H18.0742V13H16.4814V2.63672H18.0742V9.20605H18.1016L20.6719 6H22.5791L19.6875 9.37695L22.832 13Z" fill={fillColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26 1C26 0.447715 26.4477 0 27 0H53C53.5523 0 54 0.447715 54 1V15C54 15.5523 53.5523 16 53 16H29.7333L26 18V15V1ZM37.4512 13H39.085V3.19727H37.4512V7.31934H32.8369V3.19727H31.21V13H32.8369V8.74805H37.4512V13ZM47.1787 13H49.0791L47.3359 10.2451C47.1947 10.0309 47.0648 9.84408 46.9463 9.68457C46.8324 9.52051 46.7161 9.37467 46.5977 9.24707C46.4792 9.11947 46.3538 8.99642 46.2217 8.87793C46.0895 8.75488 45.9346 8.64323 45.7568 8.54297V8.51562C46.0895 8.42904 46.388 8.30599 46.6523 8.14648C46.9212 7.98698 47.1491 7.79557 47.3359 7.57227C47.5273 7.3444 47.6732 7.08691 47.7734 6.7998C47.8783 6.50814 47.9307 6.18913 47.9307 5.84277C47.9307 5.40072 47.8486 5.01562 47.6846 4.6875C47.5251 4.35482 47.3018 4.0791 47.0146 3.86035C46.7275 3.63704 46.3903 3.4707 46.0029 3.36133C45.6156 3.25195 45.1917 3.19727 44.7314 3.19727H41.5049V13H43.125V9.02148H43.7812C43.9863 9.02148 44.1709 9.04655 44.335 9.09668C44.499 9.14681 44.6494 9.22656 44.7861 9.33594C44.9274 9.44531 45.0641 9.58659 45.1963 9.75977C45.333 9.92839 45.472 10.1335 45.6133 10.375L47.1787 13ZM43.125 7.70215V4.5166H44.4922C45.0482 4.5166 45.4743 4.64876 45.7705 4.91309C46.0667 5.17741 46.2148 5.54427 46.2148 6.01367C46.2148 6.27344 46.1715 6.50814 46.085 6.71777C45.9984 6.92741 45.8776 7.10514 45.7227 7.25098C45.5677 7.39681 45.3831 7.50846 45.1689 7.58594C44.9593 7.66341 44.7292 7.70215 44.4785 7.70215H43.125Z" fill={fillColor}/>
      </svg>
    </div>
  );
}