import { IConfirmationDialogProps } from './ConfirmationDialog.types';
import React, { useState } from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';

// TODO: expose this component from profile to be reused
export const ConfirmationDialog = (props: IConfirmationDialogProps) => {
    const { title, subText, className, confirmButtonText, returnButtonText } = props;
    const [hidden, setHidden] = useState(props.hidden);

    const onConfirm = () => {
        setHidden(!hidden);
        props.onConfirm && props.onConfirm();
    };

    const onReturn = () => {
        setHidden(!hidden);
        props.onReturn && props.onReturn();
    };

    return (
        <>
            <Dialog
                hidden={hidden}
                onDismiss={onReturn}
                title={title}
                dialogContentProps={{ subText }}
                modalProps={{ isBlocking: true, className: className }}
            >
                <DialogFooter>
                    <PrimaryButton data-testid="confirm-dialog-confirm" onClick={onConfirm} text={confirmButtonText} />
                    <DefaultButton data-testid="confirm-dialog-return" onClick={onReturn} text={returnButtonText} />
                </DialogFooter>
            </Dialog>
        </>
    );
};
