export enum UserType {
    /**
     * Any user or contact
     */
    any = 'any',

    /**
     * An organization User
     */
    user = 'user',

    /**
     * An implicit or personal contact
     */
    contact = 'contact',
}

export enum PersonType {
    /**
     * Any type
     */
    any = 0,

    /**
     * A Person such as User or Contact
     */
    person = 'person',

    /**
     * A group
     */
    group = 'group',
}

export enum GroupType {
    any = 0,
    unified,
    security,
    mailenabledsecurity,
    distribution,
}
