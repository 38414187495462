import * as React from 'react';
import { useState, useEffect } from 'react';
import { CommunicationTemplate, ProgramResources } from '../../../Shared/Api/sdk.types';
import { useMentoringComponentContext } from '../../../Shared/Hooks/useMentoringComponentContext';
import { FeatureName, SubFeatureName } from '../../../Shared/Resources/TelemetryEvents';
import { EmailTemplate } from '../ProgramNotifications/EmailTemplate';
import { ICommunicationNotificationDirtyChange } from './CommunicationNotifications';
import { CommunicationsEmailFooter } from './CommunicationsEmailFooter';

interface CommunicationNotificationProps {
    template: CommunicationTemplate;
    programResources?: ProgramResources;
    onParameterChange: (newTemplate: CommunicationTemplate) => void;
    index: number;
    programId: string;
    dirtyChange: ICommunicationNotificationDirtyChange;
    onSaveClick: (index: number) => void;
    onCancelClick: () => void;
}

export const CommunicationNotification = (props: CommunicationNotificationProps) => {
    const [audienceCount, setAudienceCount] = useState<number>(0);
    const { template, index, programId, dirtyChange: { isDirtyChange, templateId } } = props;
    const { emailNotificationService, appInsightsClient } = useMentoringComponentContext();

    useEffect(() => {
        const getDeltaAudienceCount = async () => {
            const count = await emailNotificationService.getDeltaAudienceCount(programId, template.emailType, template.audience);
            setAudienceCount(count);
        }

        if (template.emailType && template.audience) {
            getDeltaAudienceCount();
        }
    }, []);

    const onTemplateSaveClick = async () => {
        props.onSaveClick(index);
    }

      const handleOnLinkClick = () => {
        appInsightsClient.logLinkClick(
          FeatureName.Communication,
          SubFeatureName.Communication_TemplateLink,
          { templateId: template.templateId, programId: programId}
        );
      };

    return (
        <div>
            <EmailTemplate
                template={template}
                editMode={(template.templateId == templateId || templateId == "")}
                onParameterChange={props.onParameterChange}
                onTemplateLinkClick= {handleOnLinkClick}
            />
            <CommunicationsEmailFooter
                emailType={template.emailType}
                audienceCount={audienceCount}
                onTemplateSaveClick={onTemplateSaveClick}
                isDirtyChange={isDirtyChange && templateId == template.templateId}
                onCancelClick={props.onCancelClick}
            />
        </div>
    )
}