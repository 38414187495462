import * as React from 'react';
import { useProgramStateSequenceLookUp } from '../../../Shared/Hooks/useProgramStateSequenceLookUp';
import { ProgramStateLabels, ProgramTrackerResources } from '../resources';
import { getProgramTrackerPanelStyles } from './ProgramTracker.styles';
import {
    IProgramState,
    IProgramStepState,
    IProgramTrackerProps,
    IProgramTrackerStepProps,
} from './ProgramTracker.types';
import { ProgramTrackerStep } from './ProgramTrackerStep';

export const ProgramTracker = (props?: IProgramTrackerProps) => {
    const styles = getProgramTrackerPanelStyles();
    const [currentProgramState, setCurrentProgramState] = React.useState(props?.currentProgramState);
    const [isInErrorState, setIsInErrorState] = React.useState(props?.isInErrorState);
    const ProgramStateSequenceLookUp = useProgramStateSequenceLookUp();

    const getSteps = (currentState: IProgramState, isInErrorState: boolean) => {
        let steps: IProgramTrackerStepProps[] = [];
        var matchFound = false;
        for (let state of ProgramStateSequenceLookUp) {
            var displayName = ProgramStateLabels[state as IProgramState]?.displayName;
            if (matchFound) {
                // if a match found with current state, rest all of the state should be in pending
                steps.push({
                    displayName,
                    state: IProgramStepState.Pending,
                });
                continue;
            }
            // if no match is found at , verify if its current state and set it to active or error state.
            if (state === currentState) {
                steps.push({
                    displayName,
                    state: isInErrorState === true ? IProgramStepState.Error : IProgramStepState.Active,
                });
                matchFound = true;
                continue;
            }

            // if no match found and its not current , then it should be completed state
            steps.push({ displayName, state: IProgramStepState.Completed });
        }
        return steps;
    };

    const [steps, setSteps] = React.useState(getSteps(props?.currentProgramState, props?.isInErrorState));
    const updateStates = () => {
        setSteps(getSteps(props?.currentProgramState, props?.isInErrorState));
        setCurrentProgramState(props?.currentProgramState);
        setIsInErrorState(props?.isInErrorState);
    };
    React.useEffect(() => {
        if (props?.currentProgramState != currentProgramState || props?.isInErrorState != isInErrorState) {
            updateStates();
        }
    }, [props?.currentProgramState, props?.isInErrorState]);

    React.useEffect(() => {
        updateStates();
    }, [ProgramStateSequenceLookUp]);

    return (
        <div>
            <div
                aria-label={ProgramTrackerResources.CreateNewProgramAriaLabel}
                role="heading"
                aria-level={1}
                className={styles.programTrackerPanelHeader}
            >
                {ProgramTrackerResources.CreateNewProgramLabel}
            </div>
            <div role="menu">
                {steps.map((step, index) => {
                    return <ProgramTrackerStep {...step} index={index} />;
                })}
            </div>
        </div>
    );
};
