import { CourseClient, ProgramServiceModuleApiException } from '../Api/sdk.types';

export class CourseService {
    private readonly courseClient: CourseClient;
    public constructor(courseClient: CourseClient) {
        this.courseClient = courseClient;
    }
    public getCourseDetails = async (courseId: string) => {
        try {
            return await this.courseClient.getCourseDetails('successFactors', courseId);
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    };
}
