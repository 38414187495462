import * as React from 'react';
import { Stack, DefaultButton, PrimaryButton, IIconProps } from '@fluentui/react';
import { EmailTemplateResources } from '../resources';
import { getCommunicationsEmailTemplateStyles } from './CommunicationsEmailFooter.styles';

const styles = getCommunicationsEmailTemplateStyles();

const stackTokens = { childrenGap: 20 };
const sendIcon: IIconProps = { iconName: 'Mail' };
interface ICommunicationsEmailFooterProps {
  isDirtyChange: boolean,
  emailType?: string,
  audienceCount?:number, 
    onTemplateSaveClick: () => void,
  onCancelClick: () => void,
} 

export const CommunicationsEmailFooter = (props:ICommunicationsEmailFooterProps) => {
    const { isDirtyChange, onCancelClick, onTemplateSaveClick } = props;
    return(
        <Stack horizontal horizontalAlign="space-between" className={styles.footer}>
          <DefaultButton disabled={!isDirtyChange} text={EmailTemplateResources.cancelButton} onClick={onCancelClick} />
          <Stack tokens={stackTokens} horizontal horizontalAlign="end">
                <PrimaryButton disabled={!isDirtyChange} text={EmailTemplateResources.save} onClick={onTemplateSaveClick}/> 
          </Stack>
        </Stack>
    );
}