import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { IProgramState } from '../ProgramTracker/ProgramTracker.types';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { ProgramCommonResources, ProgramFinishFormResources, ConfirmationDialogResources, ProgramStateLabels } from '../resources';
import { getProgramStateContentWrapperStyles } from './ProgramStateContentWrapper.styles';
import { ProgramTrackerCalloutIcon } from '../../../Shared';
interface IProgramStateContentWrapperProps {
    currentProgramSate: IProgramState;
    onSaveAsDraft: () => void;
    onContinueOrFinish: () => void;
    onBack?: () => void;
    hideBackButton?: boolean;
    isContinueButtonDisabled?: boolean;
    isSaveDraftDisabled?: boolean;
    isBackButtonDisabled?: boolean;
    isDirtyChange?: boolean;
}
export const ProgramStateContentWrapper = (props: React.PropsWithChildren<IProgramStateContentWrapperProps>) => {
    const [hideConfirmDialogOnClose, setHideConfirmDialogOnClose] = React.useState(true);

    const styles = getProgramStateContentWrapperStyles();
    const programTrackerCalloutProps = {
        header: "Header",
        description: "Description",
        ariaLabel: "AriaLabel",
        currentProgramState: props.currentProgramSate
    };

    const onClickBackButton = () => {
        if(props.isDirtyChange ){ 
            setHideConfirmDialogOnClose(!hideConfirmDialogOnClose);
        }
        else {
            props.onBack()
        }
    }
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div role="heading" aria-level={1}>
                    {ProgramStateLabels[props.currentProgramSate].header}{' '}<ProgramTrackerCalloutIcon {...programTrackerCalloutProps} />
                </div>
                {props.currentProgramSate === IProgramState.Finish && (
                    <p className={styles.subHeader}>{ProgramFinishFormResources.subHeader}</p>
                )}
            </div>
            <div>{props.children}</div>
            <div className={props.hideBackButton ? styles.containerWithOutBackButton : styles.wizardActionsContainer}>
                {!props.hideBackButton && (
                    <DefaultButton
                        text="Back"
                        onClick={onClickBackButton}
                        disabled={props.isBackButtonDisabled}
                        className={styles.backButton}
                    />
                )}
                <div className={styles.draftContinueButton}>
                    {
                        // TODO : need to enable it back post the wizard has the capability to submit mutiple forms data. till the SaveDraft on finish form doesn't make sense.
                        props.currentProgramSate !== IProgramState.Finish && props.currentProgramSate !== IProgramState.Communications && (
                            <DefaultButton
                                className={styles.draftButton}
                                text={ProgramCommonResources.saveButtonText}
                                onClick={props?.onSaveAsDraft}
                                disabled={props.isSaveDraftDisabled}
                                ariaLabel={props.isSaveDraftDisabled ? 'Save disabled' : 'Save'}
                            />
                        )
                    }
                    <PrimaryButton
                        className={styles.continueButton}
                        text={
                            props.currentProgramSate === IProgramState.Finish
                                ? ProgramCommonResources.submitButtonText
                                : ProgramCommonResources.continueButttonText
                        }
                        aria-label={props.currentProgramSate === IProgramState.Finish
                            ? ProgramCommonResources.submitButtonText
                            : ProgramCommonResources.continueButttonText}
                        onClick={props?.onContinueOrFinish}
                        disabled={props.isContinueButtonDisabled}
                    />
                </div>
            </div>
            {!hideConfirmDialogOnClose && (
                <ConfirmationDialog
                    title={ConfirmationDialogResources.title}
                    onConfirm={() =>  props?.onBack()}
                    onReturn={() => setHideConfirmDialogOnClose(true)}
                    hidden={hideConfirmDialogOnClose}
                    subText={ConfirmationDialogResources.subText}
                    confirmButtonText={ConfirmationDialogResources.confirmButtonText}
                    returnButtonText={ConfirmationDialogResources.cancelButtonText}
                />
            )}
        </div>
    );
};
