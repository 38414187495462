import * as mentoringServiceSDK from '@mentoring-services/sdk';

export type CourseClient = mentoringServiceSDK.ProgramServiceModule.CourseClient;
export type ProgramAudiencePreviewClient = mentoringServiceSDK.ProgramServiceModule.ProgramAudiencePreviewClient;
export type ProgramClient = mentoringServiceSDK.ProgramServiceModule.ProgramClient;
export type ProgramResources = mentoringServiceSDK.ProgramServiceModule.ProgramResources;
export type ResourceLink = mentoringServiceSDK.ProgramServiceModule.ResourceLink;
export type MentoringProgramRegisteredCounts = mentoringServiceSDK.ProgramServiceModule.MentoringProgramRegisteredCounts;
export type ProgramServiceModuleConfig = mentoringServiceSDK.ProgramServiceModule.IConfig;
export type UsageUserServiceModuleConfig = mentoringServiceSDK.UsageUserServiceModule.IConfig;
export type ProgramDocumentBase = mentoringServiceSDK.ProgramServiceModule.ProgramDocumentBase;
export type MentoringProgram = mentoringServiceSDK.ProgramServiceModule.MentoringProgram;
export type ProgramDetail = mentoringServiceSDK.ProgramServiceModule.ProgramDetail;
export type CommunicationTemplates = mentoringServiceSDK.ProgramServiceModule.CommunicationTemplates;
export type TemplateParameterOfString = mentoringServiceSDK.ProgramServiceModule.TemplateParameterOfString;
export type CommunicationTemplate = mentoringServiceSDK.ProgramServiceModule.CommunicationTemplate;
export type TemplateStateEnum = mentoringServiceSDK.ProgramServiceModule.TemplateStateEnum;
export type TemplateParameterOfListOfCommunicationResource = mentoringServiceSDK.ProgramServiceModule.TemplateParameterOfListOfCommunicationResource;
export type CommunicationResource = mentoringServiceSDK.ProgramServiceModule.CommunicationResource;
export type CourseDetails = mentoringServiceSDK.ProgramServiceModule.CourseDetails;
export type MentorTargetingProfile = mentoringServiceSDK.ProgramServiceModule.MentorTargetingProfile;
export type MenteeTargetingProfile = mentoringServiceSDK.ProgramServiceModule.MenteeTargetingProfile;
export type ProgramAudiencePreview = mentoringServiceSDK.ProgramServiceModule.ProgramAudiencePreview;
export type TargetingRule = mentoringServiceSDK.ProgramServiceModule.TargetingRule;
export type ProgramServiceModuleApiException = mentoringServiceSDK.ProgramServiceModule.ApiException;
export type FileParameter = mentoringServiceSDK.ProgramServiceModule.FileParameter;
export type EmailTemplateClient = mentoringServiceSDK.ProgramServiceModule.EmailTemplateClient;
export type IEmailTemplate = mentoringServiceSDK.ProgramServiceModule.EmailTemplate;
export type EmailNotificationClient = mentoringServiceSDK.ProgramServiceModule.EmailNotificationClient;
export type EmailHistoryClient = mentoringServiceSDK.ProgramServiceModule.EmailHistoryClient;
export type EmailHistoryData = mentoringServiceSDK.ProgramServiceModule.EmailHistory;
export import EmailHistoryFilterRequest = mentoringServiceSDK.ProgramServiceModule.EmailHistoryFilterRequest;
export type EmailType2 = mentoringServiceSDK.ProgramServiceModule.EmailTypeEnum;
export type QueueEmailRequest = mentoringServiceSDK.ProgramServiceModule.QueueEmailRequest;
export type SendTestEmailRequest = mentoringServiceSDK.ProgramServiceModule.SendTestEmailRequest;
export type AudienceTypeEnum2 = mentoringServiceSDK.ProgramServiceModule.AudienceTypeEnum;

export import EmailTypeEnum = mentoringServiceSDK.ProgramServiceModule.EmailTypeEnum;
export import AudienceEnum = mentoringServiceSDK.ProgramServiceModule.AudienceEnum;
export import DocumentStatusEnum = mentoringServiceSDK.ProgramServiceModule.DocumentStatusEnum;
export import RuleTypeEnum = mentoringServiceSDK.ProgramServiceModule.RuleTypeEnum;
export import TriggerTypeEnum = mentoringServiceSDK.ProgramServiceModule.TriggerTypeEnum;
export import PropertyTypeEnum = mentoringServiceSDK.ProgramServiceModule.PropertyTypeEnum;
export import ProgramStatusEnum = mentoringServiceSDK.ProgramServiceModule.ProgramStatusEnum;
export import ProgramSettings = mentoringServiceSDK.ProgramServiceModule.ProgramSettings;
export import LogicalOperator = mentoringServiceSDK.ProgramServiceModule.LogicalOperator;
export import FieldOperator = mentoringServiceSDK.ProgramServiceModule.FieldOperator;

export type TaxonomyClient = mentoringServiceSDK.DomainServiceModule.TaxonomyClient;
export type DomainsClient = mentoringServiceSDK.DomainServiceModule.DomainsClient;
export type DomainServiceModuleConfig = mentoringServiceSDK.DomainServiceModule.IConfig;
export type TaxonomyModel = mentoringServiceSDK.DomainServiceModule.TaxonomyModel;
export type IMentoringTopicsModel = mentoringServiceSDK.DomainServiceModule.IMentoringTopicsModel;
export type IMentoringTopicCategoryModel = mentoringServiceSDK.DomainServiceModule.IMentoringTopicCategoryModel;
export type IMentoringTopicValueModel = mentoringServiceSDK.DomainServiceModule.IMentoringTopicValueModel;
export type TaxonomyHierarchicalModel = mentoringServiceSDK.DomainServiceModule.TaxonomyHierarchicalModel;
