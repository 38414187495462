import * as React from 'react';

export const AccessDeniedImage = () => (
    <svg width="147" height="134" viewBox="0 0 147 134" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                d="M63.3245 2.60365C63.8304 1.05027 65.285 0 66.9292 0H72.5893H73.5379H79.1979C80.8422 0 82.2967 1.05027 82.809 2.60365L97.1836 46.545L95.7859 53.7333L101.889 60.9217L108.795 82.0339L107.53 90.3983L113.5 96.4106L120.911 119.057L73.5442 123.447L68.7695 122.862L25.2346 119.057L32.6464 96.4106L39.0907 89.2222L37.3515 82.0339L44.2574 60.9217L52.4218 53.7333L48.9562 46.545L63.3245 2.60365Z"
                fill="url(#paint0_linear)"
            />
            <path d="M44.2448 60.9216H101.889L97.1835 46.5449H48.9499L44.2448 60.9216Z" fill="url(#paint1_linear)" />
            <path d="M32.6338 96.4106H113.493L108.795 82.0339H37.3389L32.6338 96.4106Z" fill="url(#paint2_linear)" />
            <path
                d="M136.949 134H9.17774C7.08448 134 5.3833 132.308 5.3833 130.227V122.837C5.3833 120.755 7.08448 119.064 9.17774 119.064H136.949C139.043 119.064 140.744 120.755 140.744 122.837V130.227C140.744 132.308 139.049 134 136.949 134Z"
                fill="#F57F20"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="28.253"
                y1="61.7253"
                x2="142.031"
                y2="61.7253"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F57F20" />
                <stop offset="0.2666" stop-color="#F27D20" />
                <stop offset="0.4995" stop-color="#E77721" />
                <stop offset="0.7199" stop-color="#D56E23" />
                <stop offset="0.9314" stop-color="#BC6026" />
                <stop offset="1" stop-color="#B25B27" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="17.2945"
                y1="53.6"
                x2="114.614"
                y2="53.7362"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="0.463542" stop-color="#F3F3F3" />
                <stop offset="0.9999" stop-color="#E5E5E5" />
                <stop offset="1" stop-color="#E1DFDD" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="2.7778"
                y1="89.2238"
                x2="131.349"
                y2="89.2238"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#E1DFDD" />
            </linearGradient>
            <clipPath id="clip0">
                <rect width="145.167" height="134" fill="white" transform="translate(0.916748)" />
            </clipPath>
        </defs>
    </svg>
);
