import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface RestrictedRouteProps {
    isAuthorized: boolean;
}

function RestrictedRoute(props: RestrictedRouteProps & RouteProps): React.ReactElement {
    const { component: Component, isAuthorized = false, ...rest } = props;
    const render = (props: any) => {
        if (isAuthorized) {
            return <Component {...props} />;
        }

        return <Redirect to="/accessdenied" />;
    };

    return <Route {...rest} render={render} />;
}

export default RestrictedRoute;
