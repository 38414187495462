import { HttpClient } from '@employee-experience/core';
import {
    EmailTemplateClient,
    ProgramServiceModuleApiException
} from '../Api/sdk.types';

export class EmailTemplateService {
    private readonly emailTemplateClient: EmailTemplateClient;

    public constructor(emailTemplateClient: EmailTemplateClient) {
        this.emailTemplateClient = emailTemplateClient;
    }

  

    public async getListTemplates() {
        try {
            return await this.emailTemplateClient.listTemplates();
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    }
}
