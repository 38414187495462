import { useId, useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useRef, useState} from 'react';
import { Callout, Link, DirectionalHint, IconButton, Text, DelayedRender } from 'office-ui-fabric-react';
import * as Styles from './InformationCallOutIcon.styles';
import { getClassNames } from './InformationCallOutIcon.styles';
const styles = getClassNames();
interface InformationCallOutIconProps  { 
    header: string, 
    description: string, 
    list?: string[], 
    ariaLabel?: string, 
    id? : string 
}

export const InformationCallOutIcon = (props: InformationCallOutIconProps) => {
    const { header, description, list } = props;
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId(props.id);
    return (<div className={styles.iconArea}>
        <IconButton 
            iconProps={{ iconName: "Info" }} 
            id={buttonId}  
            onMouseOver={toggleIsCalloutVisible}
            onFocus={toggleIsCalloutVisible} 
            onMouseOut={toggleIsCalloutVisible} 
            ariaLabel= {props?.ariaLabel}
        />
        {isCalloutVisible && (
            <Callout
                className={styles.callout}
                role="alert"
                gapSpace={0}
                target={`#${buttonId}`} 
                onDismiss={toggleIsCalloutVisible}
                directionalHint={DirectionalHint.bottomLeftEdge}
                styles={Styles.callOutStyle}
            >
                <DelayedRender>
                    <div className={styles.callOutPadding}>
                        <Text block variant="large">
                            {header}
                        </Text>
                        <Text block variant="small">
                            {description}
                        </Text>
                        {list &&
                        <ul className={styles.unOrderedList} role="alert">
                        { list.map((item: string, index: number ) => {
                                return (<li className={styles.list} key={index}> {item} </li>)
                            })}
                        </ul>}
                    </div>
                </DelayedRender>
            </Callout>
        )}
    </div>
    );
};