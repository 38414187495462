import { IStyle, mergeStyleSets } from "@fluentui/react"

const backToPortal: IStyle = {
    paddingBottom: '20px'
}

export const programNotificationContainer = {
    marginTop: '30px', 
    marginLeft: '8px',  
    maxWidth: '1227px',
    width: '100%'
}

const pivot: IStyle = {
    marginTop: '16px'
}

const messageBarContanier: IStyle = {
    paddingBottom: '10px',
};

const communicationHeader: IStyle = {
    paddingLeft: '8px',
};

export const getProgramNotificationStyle = () => {
    return mergeStyleSets({backToPortal, pivot, messageBarContanier, communicationHeader });
}