import { useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Callout, Link, DirectionalHint, IconButton, Text } from 'office-ui-fabric-react';
import * as Styles from './ProgramTrackerCalloutIcon.styles';
import { getClassNames } from './ProgramTrackerCalloutIcon.styles';
import { getAdminProgramStyles } from '../../../Pages/Admin/CreateProgram/CreateProgram.styles';
import { ProgramTracker } from '../../../Pages/Admin/ProgramTracker/ProgramTracker';
import { IProgramState } from '../../../Pages/Admin/ProgramTracker/ProgramTracker.types';
import { LoadingStates } from '../../../Shared';
const styles = getClassNames();

interface ProgramTrackerCalloutIconViewState {
    loadingState: LoadingStates;
    upsertState: LoadingStates;
    currentProgramState: IProgramState;
    showSubmitConfirmationModal?: boolean;
}
export const ProgramTrackerCalloutIcon = (props: { header: string, description: string, list?: string[], ariaLabel?: string, currentProgramState: IProgramState }) => {
    const { header, description, list } = props;
    const [isCalloutVisible, expand] = useState(false);
    const iconRef = useRef<HTMLDivElement | null>(null);
    const descriptionId: string = useId('callout-description');
    const [toggle, setToggle] = React.useState(false);
    const adminStyles = getAdminProgramStyles();
    const [programTrackerCalloutIconViewState, setProgramTrackerCalloutIconViewState] = useState({
        loadingState: LoadingStates.STARTED,
        program: null,
        upsertState: LoadingStates.NOT_STARTED,
        currentProgramState: props.currentProgramState,
    } as ProgramTrackerCalloutIconViewState);
    const [isInErrorState, setIsInErrorState] = React.useState(false);
    function setCallOutVisible(toggle: boolean) {
        if (!toggle) {
            expand(true)
        }
        else {
            expand(false)
        }

        setToggle((prevExpand) => !prevExpand)
    }
    function setOnDismiss() {
        setToggle((prevExpand) => !prevExpand)
        expand(!isCalloutVisible)
    }

    return (
        <div className={styles.iconArea}>
            <span ref={iconRef}>
                <IconButton
                    iconProps={{ iconName: toggle === true ? 'ChevronDown' : 'ChevronUp' }}
                    onClick={() => setCallOutVisible(toggle)}
                    id={props.header}
                    ariaLabel={props?.ariaLabel}
                />
            </span>
            {isCalloutVisible && (
                <Callout
                    className={styles.callout}
                    ariaDescribedBy={descriptionId}
                    role="callout"
                    gapSpace={0}
                    target={iconRef.current}
                    onDismiss={setOnDismiss}
                    isBeakVisible={false}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    directionalHintForRTL={DirectionalHint.bottomRightEdge}
                    styles={Styles.callOutStyle}
                >
                    <div className={styles.callOutPadding}>
                        <div className={adminStyles.programTrackerPanelCallOut}>
                            <ProgramTracker
                                currentProgramState={programTrackerCalloutIconViewState?.currentProgramState}
                                isInErrorState={isInErrorState}
                            />
                        </div>
                        {list &&
                            <ul className={styles.unOrderedList}>
                                {list.map((item: string, index: number) => {
                                    return (<li className={styles.list} key={index}> {item} </li>)
                                })}
                            </ul>}
                    </div>
                </Callout>
            )}
        </div>
    );

};