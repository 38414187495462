import { FontWeights, IStyle, mergeStyleSets } from 'office-ui-fabric-react';

const wizardActionsContainer: IStyle = {
    justifyContent: 'space-between',
    padding: '32px 0px 41px 0px',
    selectors: {
        '@media (min-width: 1365px)': {
            width: '876px',
        },
        '@media (min-width:640px) and (max-width: 1364px)': {
            width: '576px',
        },
        '@media (max-width: 479px)': {
            flexWrap: 'wrap',
        },
    },
};

const wizardActionsContainerFlex: IStyle = {
    display: 'flex'
};

const backButtonStyle: IStyle = {
    '@media (max-width: 479px)': {
        width: '100%',
        marginBottom: '14px',
    },
};

const draftContinueButtonStyle: IStyle = {
    display: 'flex',
    float: 'right',
    width: '232px',
    '@media (max-width: 479px)': {
        width: '100%',
    },
    position: 'relative'
};

const draftButtonStyle: IStyle = {
    marginRight: '8px',
    '@media (max-width: 479px)': {
        width: '50%',
        marginRight: '16px',
    },
};

const continueButtonStyle: IStyle = {
    '@media (max-width: 479px)': {
        width: '50%',
    },
    position: 'absolute',
    right: 0
};

const marginRight: IStyle = {
    marginRight: '16px',
};

const header: IStyle = {
    fontSize: '26px',
    fontWeight: FontWeights.bold,
    paddingBottom: '32px',
    borderBottom: '1px solid #F3F2F1;',
};

const subHeader: IStyle = {
    fontSize: '18px',
    fontWeight: FontWeights.semibold,
    marginTop: '16px',
};

const root: IStyle = {
    minHeight: '200px',
};

export const getProgramStateContentWrapperStyles = () => {
    return mergeStyleSets({
        root,
        wizardActionsContainer: [wizardActionsContainer, wizardActionsContainerFlex],
        containerWithOutBackButton: wizardActionsContainer,
        header,
        subHeader,
        marginRight,
        backButton: backButtonStyle,
        draftContinueButton: draftContinueButtonStyle,
        draftButton: draftButtonStyle,
        continueButton: continueButtonStyle,
    });
};
