import {
    IComboBox,
    IComboBoxOption,
    IconButton,
    IPersonaProps,
    Text,
    VirtualizedComboBox,
} from 'office-ui-fabric-react';
import { Dropdown } from '@fluentui/react';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { LogicalOperator, PeoplePicker, PickerType, PropertyTypeEnum, TargetingRule } from '../../../../Shared';
import { HierarchicalDataPicker } from '../../../../Shared/Components/HierarchicalDataPicker/HierarchicalDataPicker';
import { useMentoringComponentContext } from '../../../../Shared/Hooks/useMentoringComponentContext';
import { OptionKeyTextPair } from '../../../../Shared/Models/OptionKeyTextPair';
import { isNullOrUndefined } from '../../../../Shared/Utilities';
import { ConditionComposerResources, ConditionGroupResources } from './resources';
import {
    getConditionComposerStyles,
    peoplePickerWrapperStyles,
    successIconButton,
    warningIconButton,
} from './ConditionComposer.styles';

enum ConditionAttributeType {
    PropertyType = 'propertyType',
    ConditionType = 'conditionType',
    CompareValue = 'compareValue',
}

export interface ConditionComposerProps {
    onAddCondition: () => void;
    onRemoveCondition: () => void;
    condition: TargetingRule;
    onConditionChange: (modifiedCondition: TargetingRule) => void;
    showConjunctionType: boolean;
    conjunctionJoinType: LogicalOperator;
}
export interface ConditionComposerRef {
    validate: () => void;
}
export const ConditionComposer = React.forwardRef(
    (props: ConditionComposerProps, ref: React.Ref<ConditionComposerRef>) => {
        const styles = getConditionComposerStyles();
        const [multiSelectOptionsDomainData, setMultiSelectOptionsDomainData] = useState([]);
        const [disciplineHierarchyDataSource, setDisciplineHierarchyDataSource] = useState([]);
        const [comboBoxText, setComboBoxText] = useState('');
        const [previousSelectedOptions, setPreviousSelectedOptions] = useState<OptionKeyTextPair[]>();
        const { taxonomyService } = useMentoringComponentContext();
        const [errors, setErrors] = useState({} as any);

        React.useImperativeHandle(ref, () => ({
            validate: () => {
                let errorMessage = '';
                let updatedErrors = { ...errors };
                if (isNullOrUndefined(props?.condition?.propertyType)) {
                    updatedErrors[ConditionAttributeType.PropertyType] =
                        ConditionComposerResources.errorMessage.FieldCannotBeBlank;
                    setErrors(updatedErrors);
                } else if (isNullOrUndefined(props?.condition?.conditionType)) {
                    updatedErrors[ConditionAttributeType.ConditionType] =
                        ConditionComposerResources.errorMessage.OperatorCannotBeBlank;
                    updatedErrors[ConditionAttributeType.PropertyType] = '';
                    setErrors(updatedErrors);
                } else {
                    updatedErrors[ConditionAttributeType.ConditionType] = '';
                    updatedErrors[ConditionAttributeType.PropertyType] = '';
                    const value = props?.condition?.compareValue;
                    const hasNoValue =
                        isNullOrUndefined(value) ||
                        (Array.isArray(value) && (value?.length === 0 || value?.some((value) => !!value === false))) ||
                        (typeof value === 'boolean' && value === false);

                    if (hasNoValue) {
                        switch (props?.condition?.propertyType) {
                            case PropertyTypeEnum.RTDChain:
                                errorMessage = ConditionComposerResources.errorMessage.InvalidOrganizationLeader;
                                break;
                            case PropertyTypeEnum.MemberOfGroups:
                                errorMessage = ConditionComposerResources.errorMessage.InvalidSecurityGroup;
                                break;
                            case PropertyTypeEnum.City:
                            case PropertyTypeEnum.Country:
                            case PropertyTypeEnum.Region:
                            case PropertyTypeEnum.Discipline:
                            case PropertyTypeEnum.Profession:
                            case PropertyTypeEnum.CareerStage:
                                errorMessage = ConditionComposerResources.errorMessage.ValueCannotBeBlank;
                                break;
                            default:
                                break;
                        }
                    }
                    updatedErrors[ConditionAttributeType.CompareValue] = errorMessage;
                    setErrors(updatedErrors);
                }
            },
        }));

        const handleChange = (attribute: ConditionAttributeType, newValue: any) => {
            let updatedCondition = { ...props?.condition, [attribute]: newValue };
            let updatedErrorr = { ...errors };
            if (attribute === ConditionAttributeType.PropertyType) {
                // if property type itself changes make sure to reset rest of condition props like value and condition type
                updatedCondition.conditionType = null;
                updatedCondition.compareValue = [];

                delete updatedErrorr[ConditionAttributeType.ConditionType];
                delete updatedErrorr[ConditionAttributeType.CompareValue];
            }
            if (!isNullOrUndefined(newValue)) {
                delete updatedErrorr[attribute];
            }
            setErrors(updatedErrorr);
            props?.onConditionChange(updatedCondition);
        };

        const handlePickerUpdate = (selectedItems: IPersonaProps[]) => {
            const selectedItemIds = selectedItems?.map((item) => item.id);
            handleChange(
                ConditionAttributeType.CompareValue,
                selectedItemIds && selectedItemIds?.length > 0 && selectedItemIds[0]
            );
        };

        const handleMultiSelectDropdownUpdate = (event: React.FormEvent<IComboBox>, item: IComboBoxOption): void => {
            if (item) {
                let originalValue = props?.condition?.compareValue as string[];
                const updatedValue = item?.selected
                    ? [...originalValue, item.key as string]
                    : originalValue.filter((key) => key !== item.key);
                // if an option is selected or  unselected update local  previousSelectedOptions .
                // so that this list can be used to append to the filtered source when user types any filter query.
                item?.selected
                    ? setPreviousSelectedOptions((prevOptions) => {
                          let selectedOptions = prevOptions ? [...prevOptions] : [];
                          selectedOptions.push({ key: item.key as string, text: item.text });
                          return selectedOptions;
                      })
                    : setPreviousSelectedOptions((prevOptions) => {
                          let selectedOptions = prevOptions
                              ? prevOptions.filter((option) => option.key !== item.key)
                              : prevOptions;
                          return selectedOptions;
                      });
                // clear user inputed text,
                // so that selcted option will be shown to user as placeholder content with ","  seperated
                setComboBoxText('');
                handleChange(ConditionAttributeType.CompareValue, updatedValue);
            }
        };

        const handleHierarchicalDataPicker = (selectedItems: ChildItem[]) => {
            const selectedKeys: string[] = selectedItems.map((item) => item.id);
            handleChange(ConditionAttributeType.CompareValue, selectedKeys);
        };

        const fetchDomianData = async (searchParam?: string) => {
            let domainSource: OptionKeyTextPair[] = await taxonomyService.getDomainData(
                props?.condition?.propertyType,
                searchParam,
                props?.condition?.compareValue as string[]
            );
            if (previousSelectedOptions && previousSelectedOptions?.length > 0) {
                let selectedOptionsToMerge: OptionKeyTextPair[] = [];
                previousSelectedOptions.forEach((option) => {
                    if (domainSource.some((item) => item.key === option.key) === false) {
                        selectedOptionsToMerge.push(option);
                    }
                });
                domainSource = [...selectedOptionsToMerge, ...domainSource];
            }

            setMultiSelectOptionsDomainData(domainSource);
        };

        const fetchDisciplineHierarchyData = async () => {
            let data = await taxonomyService.getProfessionDisciplineData();
            let displayTextArray: string[] = [];
            const disciplineData = data.map((profession) => {
                let children = profession.children.map((child) => {
                    let childItem = {
                        id: child.externalId,
                        name: child.name,
                        parentId: profession.externalId,
                        selected:
                            props?.condition?.compareValue !== null &&
                            props?.condition?.compareValue !== undefined &&
                            (props?.condition?.compareValue as string[])?.some(
                                (selectedItem) => child.externalId === selectedItem
                            ),
                    };
                    if (childItem?.selected) {
                        displayTextArray.push(childItem.name);
                    }
                    return childItem;
                });
                return {
                    id: profession.externalId,
                    name: profession.name,
                    children,
                };
            });
            setDisciplineHierarchyDataSource(disciplineData);
        };

        useEffect(() => {
            if (
                (props?.condition?.propertyType !== null || props?.condition?.propertyType !== undefined) &&
                (props?.condition?.propertyType === PropertyTypeEnum.Profession ||
                    props?.condition?.propertyType === PropertyTypeEnum.City ||
                    props?.condition?.propertyType === PropertyTypeEnum.Country ||
                    props?.condition?.propertyType === PropertyTypeEnum.Region ||
                    props?.condition?.propertyType === PropertyTypeEnum.CareerStage)
            ) {
                fetchDomianData();
            }

            if (
                (props?.condition?.propertyType !== null || props?.condition?.propertyType !== undefined) &&
                props?.condition?.propertyType === PropertyTypeEnum.Discipline
            ) {
                fetchDisciplineHierarchyData();
            }
        }, [props?.condition?.propertyType]);

        return (
            <div className={styles.root}>
                <div className={styles.column}>
                    {props?.showConjunctionType && (
                        <Text>{ConditionGroupResources.JoinTypeDescriptions[props?.conjunctionJoinType]}</Text>
                    )}
                </div>
                <Dropdown
                    errorMessage={errors[ConditionAttributeType.PropertyType]}
                    className={styles.column}
                    ariaLabel={ConditionGroupResources.ariaLabelPropertyFieldPlaceHolder}
                    placeholder={ConditionGroupResources.propertyFieldPlaceHolder}
                    options={ConditionGroupResources.eligibileCriteriaFieldOptions}
                    selectedKey={props?.condition?.propertyType}
                    onChange={(_, option) => handleChange(ConditionAttributeType.PropertyType, option?.key)}
                />
                {props?.condition?.propertyType !== null && props?.condition?.propertyType !== undefined && (
                    <Dropdown
                        errorMessage={errors[ConditionAttributeType.ConditionType]}
                        className={styles.column}
                        ariaLabel={ConditionGroupResources.ariaLabeloperatorPlaceholder}
                        placeholder={ConditionGroupResources.operatorPlaceholder}
                        selectedKey={props?.condition?.conditionType}
                        options={
                            ConditionGroupResources.PropertyTypeOperatorOptionMapping[props?.condition?.propertyType]
                                ?.options
                        }
                        onChange={(_, option) => handleChange(ConditionAttributeType.ConditionType, option?.key)}
                    />
                )}
                {props?.condition?.conditionType !== null && props?.condition?.conditionType !== undefined && (
                    <>
                        {props?.condition?.propertyType === PropertyTypeEnum.MemberOfGroups && (
                            <PeoplePicker
                                pickerLimit={1}
                                className={styles.column}
                                styles={{
                                    pickerStyles: styles.peoplePicker,
                                    root: styles.column,
                                    basePickerStyles: peoplePickerWrapperStyles,
                                }}
                                pickerType={PickerType.Group}
                                handleOnChange={(selectedItems) => {
                                    handlePickerUpdate(selectedItems);
                                }}
                                errorMessage={errors[ConditionAttributeType.CompareValue]}
                                defaultPickerItemsIds={[props?.condition?.compareValue as string]}
                            />
                        )}
                        {props?.condition?.propertyType === PropertyTypeEnum.RTDChain && (
                            <PeoplePicker
                                pickerLimit={1}
                                className={styles.column}
                                styles={{
                                    pickerStyles: styles.peoplePicker,
                                    root: styles.column,
                                    basePickerStyles: peoplePickerWrapperStyles,
                                }}
                                pickerType={PickerType.People}
                                handleOnChange={(selectedItems) => {
                                    handlePickerUpdate(selectedItems);
                                }}
                                errorMessage={errors[ConditionAttributeType.CompareValue]}
                                defaultPickerItemsIds={[props?.condition?.compareValue as string]}
                            />
                        )}

                        {(props?.condition?.propertyType === PropertyTypeEnum.Profession ||
                            props?.condition?.propertyType === PropertyTypeEnum.City ||
                            props?.condition?.propertyType === PropertyTypeEnum.Country ||
                            props?.condition?.propertyType === PropertyTypeEnum.Region ||
                            props?.condition?.propertyType === PropertyTypeEnum.CareerStage) && (
                            <VirtualizedComboBox
                                errorMessage={errors[ConditionAttributeType.CompareValue]}
                                useComboBoxAsMenuWidth={true}
                                autoComplete="off"
                                autofill={{
                                    onInputValueChange: (newValue, composing) => {
                                        composing === false && fetchDomianData(newValue);
                                        setComboBoxText(newValue);
                                    },
                                }}
                                allowFreeform={true}
                                onMenuDismiss={() => setComboBoxText('')}
                                text={comboBoxText}
                                calloutProps={{ calloutMaxHeight: 350 }}
                                className={styles.column}
                                ariaLabel={ConditionGroupResources.ariaLabelForselectValues}
                                placeholder={ConditionGroupResources.selectValues}
                                selectedKey={props?.condition?.compareValue as string[]}
                                options={multiSelectOptionsDomainData}
                                multiSelect={true}
                                onChange={handleMultiSelectDropdownUpdate}
                            />
                        )}
                        {props?.condition?.propertyType === PropertyTypeEnum.Discipline && (
                            <HierarchicalDataPicker
                                errorMessage={errors[ConditionAttributeType.CompareValue]}
                                className={styles.column}
                                data={disciplineHierarchyDataSource}
                                onChange={(selectedItems) => handleHierarchicalDataPicker(selectedItems)}
                            />
                        )}
                    </>
                )}
                <div className={styles.column}>
                    <IconButton
                        styles={successIconButton}
                        ariaLabel={'Add'}
                        onClick={() => props?.onAddCondition()}
                        iconProps={{ iconName: 'Add' }}
                        data-is-focusable={true}
                    />
                    <IconButton
                        styles={warningIconButton}
                        ariaLabel={'Cancel'}
                        onClick={() => props?.onRemoveCondition()}
                        iconProps={{ iconName: 'Cancel' }}
                        data-is-focusable={true}
                    />
                </div>
            </div>
        );
    }
);
