import * as GraphMockService from './GraphMockServices';
import { User, Group } from '@microsoft/microsoft-graph-types';
import { GraphService } from './GraphService';
import { isProduction } from '../Utilities';

interface IPeoplePickerItem {
    /**
     * LoginName or Id of the principal in the site.
     */
    id: string;
    /**
     * LoginName of the principal.
     */
    loginName: string;
    imageUrl: string;
    imageInitials: string;
    text: string; // name
    secondaryText: string; // role
    tertiaryText: string; // status
    optionalText: string; // anything
}

export class PeoplePickerService {
    private readonly graphService: GraphService;

    public constructor(graphService: GraphService) {
        this.graphService = graphService;
    }
    public async findUsers(query: string, top: number = 10): Promise<IPeoplePickerItem[]> {
        let users = await this.graphService.findUsers(query, top);
        return await this.transformUserToPickerItem(users);
    }

    public async findPeople(query: string, top: number = 10): Promise<IPeoplePickerItem[]> {
        let people = await this.graphService.findPeople(query, top);
        let pickerItems: IPeoplePickerItem[] = [];
        if (people && people?.length > 0) {
            pickerItems = await Promise.all(
                people.map(async (user) => {
                    const imageUrl = await this.graphService.getPhotoForResource(user.id);
                    return {
                        id: user.id,
                        imageUrl,
                        imageInitials: this.getFullNameInitials(user.displayName),
                        text: user.displayName, // name
                        secondaryText: user.userPrincipalName, // email
                        tertiaryText: '', // status
                        optionalText: '', // anything
                    } as IPeoplePickerItem;
                })
            );
        }
        return pickerItems;
    }

    public async findGroups(query: string, top: number = 10): Promise<IPeoplePickerItem[]> {
        let groups =
            isProduction() === true
                ? await this.graphService.findGroups(query, top)
                : await GraphMockService.findGroups();
        return this.transformGroupsToPickerItem(groups);
    }

    public async getGroupsForGroupIds(groupIds: string[]): Promise<IPeoplePickerItem[]> {
        let groups =
            isProduction() === true
                ? await this.graphService.getGroupsForGroupIds(groupIds)
                : await GraphMockService.getGroupsForGroupIds(groupIds);
        return this.transformGroupsToPickerItem(groups);
    }

    public async getUsersForUserIds(userIds: string[]): Promise<IPeoplePickerItem[]> {
        let users = await this.graphService.getUsersForUserIds(userIds);
        return await this.transformUserToPickerItem(users);
    }

    private async transformUserToPickerItem(users: User[]) {
        let pickerItems: IPeoplePickerItem[] = [];
        if (users && users?.length > 0) {
            pickerItems = await Promise.all(
                users.map(async (user) => {
                    const imageUrl = await this.graphService.getPhotoForResource(user.id);
                    return {
                        id: user.id,
                        imageUrl,
                        imageInitials: this.getFullNameInitials(user.displayName),
                        text: user.displayName, // name
                        secondaryText: user.userPrincipalName, // email
                        tertiaryText: '', // status
                        optionalText: '', // anything
                    } as IPeoplePickerItem;
                })
            );
        }

        return pickerItems;
    }

    private transformGroupsToPickerItem(groups: Group[]) {
        let pickerItems: IPeoplePickerItem[] = [];
        if (groups && groups?.length > 0) {
            pickerItems = groups.map((group) => {
                return {
                    id: group.id,
                    imageInitials: this.getFullNameInitials(group.displayName),
                    text: group.displayName, // name
                    secondaryText: group.mail, // email
                    tertiaryText: '', // status
                    optionalText: '', // anything
                } as IPeoplePickerItem;
            });
        }

        return pickerItems;
    }

    private getFullNameInitials(fullName: string): string {
        if (!!fullName === false) {
            return fullName;
        }

        const words: string[] = fullName.split(' ');
        if (words.length === 0) {
            return '';
        } else if (words.length === 1) {
            return words[0].charAt(0);
        } else {
            return words[0].charAt(0) + words[1].charAt(0);
        }
    }
}
