import * as OTel from '@microsoft/oteljs';
import { OneDSSink, OneDSEndpoint } from '@microsoft/oteljs-1ds';

export class TelemetrySinkProvider {
  private sink: OTel.TelemetrySink | undefined;

  // This is the callback that the Floodgate SDK will call to get the TelemetrySink.
  public getTelemetrySink(): OTel.TelemetrySink {
    if (!this.sink) {
      this.sink = this.createSink();
    }
    return this.sink;
  }

  // daespino note: Create an Otel.TelemetrySink to pass it to the FG SDK so it can be attached to the SDK's internal logger.
  // Reference this wiki page for OneDSSink creation, the TelemetryLogger is not needed because FG SDK implements it internally:  https://www.owiki.ms/wiki/Telemetry/OTelJS#1DS_sink
  private createSink(isEUDBUser: boolean = false): OTel.TelemetrySink {
    // Setting the required persistent data fields. Host sets User, App and Session fields for themselves.
    let persistentDataFields: OTel.DataField[] = [
      ...OTel.User.getFields({
        tenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47', // should be a real GUID
        isAnonymous: true,
      }),
      ...OTel.App.getFields({
        name: 'Mentoring',
        platform: 'Web',
        version: '1.0.0',
      }),
    ];

    // For EUDB users, use OneDSEndpoint.EUDB. The host app has the context on which to use and has to set it appropriately.
    const oneDsSink = isEUDBUser
      ? new OneDSSink(persistentDataFields, { endpointUrl: OneDSEndpoint.EUDB })
      : new OneDSSink(persistentDataFields, { endpointUrl: OneDSEndpoint.PUBLIC });

    return oneDsSink;
  }
}
