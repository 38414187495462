import * as React from 'react';
import { Stack, DefaultButton, PrimaryButton, IIconProps } from '@fluentui/react';
import { EmailTemplateResources, ProgramCommonResources } from '../resources';
import { getEmailTemplateStyles } from './EmailTemplate.styles';
import { useIsUserInFeature } from '../../../Shared/Hooks/useIsUserInFeature';
import { FeatureNames } from '../../../Shared/Resources/FeatureNames';

const styles = getEmailTemplateStyles();

const stackTokens = { childrenGap: 20 };
const sendIcon: IIconProps = { iconName: 'Mail' };
interface IEmailTemplateFooterProps {
    editMode: boolean;
    emailType: string;
    audienceCount?: number;
    isSaveDraftDisabled?: boolean;
    isSendEmailDisabled?: boolean;
    onEditClick: () => void;
    onSaveClick: () => void;
    onSendEmail: () => void;
    onSendTestEmail: () => void;
    onCancelClick: () => void;
}

export const EmailTemplateFooter = (props: IEmailTemplateFooterProps) => {
    const testEmailFeature = useIsUserInFeature(FeatureNames.TESTEMAIL);

    return (
        <Stack horizontal horizontalAlign="space-between" className={styles.footer}>
            <div className={styles.recipientCount}>
                {props.audienceCount != undefined && (
                    <span>{`${EmailTemplateResources.totalEmail} ${props.audienceCount}`}</span>
                )}
            </div>
            <div className={styles.emailFooterButtonStyles}>
                {props.editMode ? (
                    <>
                        <DefaultButton
                            className={styles.editButtonStyles}
                            text={EmailTemplateResources.cancelButton}
                            onClick={props.onCancelClick}
                        />
                        <PrimaryButton
                            className={styles.editButtonStyles}
                            text={ProgramCommonResources.saveButtonText}
                            onClick={props.onSaveClick}
                            disabled={!props.isSaveDraftDisabled}
                        />
                    </>
                ) : (
                    <>
                        <DefaultButton
                            className={styles.buttonStyles}
                            iconProps={sendIcon}
                            text={EmailTemplateResources.editEmail}
                            aria-label={EmailTemplateResources.editEmail}
                            onClick={props.onEditClick}
                        />
                        {testEmailFeature && (
                            <DefaultButton
                                className={styles.buttonStyles}
                                iconProps={sendIcon}
                                text={EmailTemplateResources.sendTestEmail}
                                aria-label={EmailTemplateResources.sendTestEmail}
                                onClick={props.onSendTestEmail}
                            />
                        )}
                        <PrimaryButton
                            className={styles.buttonStyles}
                            iconProps={sendIcon}
                            text={EmailTemplateResources.sendEmail}
                            aria-label={EmailTemplateResources.sendEmail}
                            disabled={props.audienceCount == 0 || props.isSendEmailDisabled}
                            onClick={props.onSendEmail}
                        />
                    </>
                )}
            </div>
        </Stack>
    );
};
