import {
    mergeStyleSets,
    memoizeFunction,
    IStyle
} from 'office-ui-fabric-react';

const footerLinkStyle: IStyle = {
    selectors: {
        '&:focus': { textDecoration: "underline" }
    }
}

const copyRightStyle: IStyle = {
    paddingLeft: 86
}
export const getClassNames = memoizeFunction(
    // tslint:disable-next-line:deprecation
    () => {
        return mergeStyleSets({
            root: ["mch-app-footer",
                {
                    minHeight: 40,
                    marginRight: 40
                }], 
                footerLink:  footerLinkStyle,
                copyRight : copyRightStyle
        });
    },
)