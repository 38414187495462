import {
    mergeStyleSets,
    DefaultPalette,
    ICalloutContentStyleProps,
    IStyleFunctionOrObject,
    ICalloutContentStyles
} from 'office-ui-fabric-react';
import { NeutralColors } from '@cseo/styles';


export const callOutStyle: IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles> = {
    beak: {
        backgroundColor: DefaultPalette.themePrimary,
    },
}

export const getClassNames = () => {
    return mergeStyleSets({
        iconArea: {
            verticalAlign: 'middle',
            display: 'inline',
            height: "16px",
            selectors: {
                '@media (min-width: 1024px)': {
                    display: 'none',
                },
            },
        },
        callout: {
            maxWidth: 300,
            fontSize: 12,
        },
        callOutPadding: {
            backgroundColor: NeutralColors.white,
            color: NeutralColors.black,
            padding: "20px 24px 32px 24px"
        },
        unOrderedList: {
            paddingLeft: "10px",
            marginTop: "10px"
        },
        list: {
            listStyleType: "circle",
        },
    });
}