import { IStyle, mergeStyleSets, IDocumentCardStyles } from 'office-ui-fabric-react';
import { TextColors } from '@cseo/styles';
import { FontWeights, FontSizes } from "office-ui-fabric-react";

const documentCard: IStyle = {
    width: '378px',
    '@media (max-width: 479px)': {
        width: '100%',
    },
};

const noProgramHeader: IStyle = {
    paddingBottom: '16px',
};

const refresh: IStyle = {
    cursor: 'pointer',
};

const iconColor: IStyle = {
    color: '#0078D4',
};

const iconText: IStyle = {
    paddingLeft: '7px',
};

const newProgram: IStyle = {
    paddingLeft: '20px',
    cursor: 'pointer',
};

const noProgramDescription: IStyle = {
    paddingBottom: '48px',
};

const createNewProgram: IStyle = {
    maxWidth: '200px',
};

export const programDocumentCard: IDocumentCardStyles = {
    root: {
        minHeight: '528px',
        margin: '10px',
        maxWidth: '378px',
        minWidth: '378px',
        '@media (max-width: 479px)': {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: '100%',
            minWidth: '100%',
        },
    },
};

const programHeaderSection: IStyle = {
    borderBottom: '1px solid #E5E5E5',
    height: '40px',
    width: '100%',
    display: 'table'
};

const headerStyle: IStyle = {
    fontSize: FontSizes.size14,
    display:'table-cell',
    verticalAlign: 'middle',
    padding: '0px 16px 0px 16px'
}

const headerSectionIcon: IStyle = {
    padding: '0px 8px',
    cursor: 'pointer',
};

const programProfileContainer: IStyle = {
    padding: '20px 20px 0px 20px'
};
const programInformation: IStyle = {
    paddingLeft: '20px',
};

const createdProgram: IStyle = {
    fontSize: '12px',
    paddingBottom: '3px',
};

const fontStyle: IStyle = {
    fontSize: FontSizes.size12
};

const admin: IStyle = {
    paddingTop: '5px',
};

const ownerImage: IStyle = {
    paddingTop: '0px',
    paddingBottom: '0px',
    width: '112px'
};

const membershipInformationContainer: IStyle = {
    padding: '30px 20px 55px'
};

const membershipHeader: IStyle = {
    fontWeight: FontWeights.semibold
};

const membershipInformationItem: IStyle = {
    fontWeight: FontWeights.semibold,
    padding: '10px 0px 10px 0px',
};

const membershipInformationItemBorder: IStyle = {
    height: '40px',
    borderLeft: '2px solid #92D5CD',
    paddingLeft: '10px',
};

const ButtonStyle: IStyle = {
    minHeight: '32px',
    marginLeft: '20px',
    marginBottom: '20px',
    whiteSpace: 'nowrap',
    '@media (max-width: 479px)': {
        marginLeft: '10px',
        marginRight: '10px',    
    },
}

const programDetailButton: IStyle = {
    '@media (max-width: 479px)': {
        width: '100%',
    },
    width: '42%'
};

const programDetailButtonSingle: IStyle = {
    marginTop: '50px',
    width: '89%',
};

const manageCommunicationsButton: IStyle = {
    marginRight: '20px',
}

export const createProgramDocumentCard: IDocumentCardStyles = {
    root: {
        height: '252px',
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '378px',
        minWidth: '378px',
        '@media (max-width: 479px)': {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: '100%',
            minWidth: '100%',
        },
    },
};

const createProgramIcon: IStyle = {
    cursor: 'pointer',
    color: TextColors.secondary,
    fontSize: '36px',
};

const spinner: IStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
};

const srOnlyStyle: IStyle = {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
    clipRule: 'rect(0,0,0,0)'
};

const mainContainer: IStyle = {
    padding: 50,
    selectors: {
        '@media (max-width: 479px)': {
            paddingLeft: 10,
            paddingRight: 10
        },
    },
}

const manageCommunicationsButtonProd: IStyle = {
    minHeight: '32px',
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    whiteSpace: 'nowrap'
}

const programDetailButtonProd: IStyle = {
    minHeight: '32px',
    marginLeft: '20px',
    marginBottom: '20px',
    whiteSpace: 'nowrap',
    '@media (max-width: 479px)': {
        marginLeft: '10px'
    },
};

const membershipInformationContainerProd: IStyle = {
    padding: '85px 20px 55px'
};

export const getAdminPortalStyles = () => {
    return mergeStyleSets({
        documentCard,
        noProgramHeader,
        refresh,
        iconColor,
        iconText,
        newProgram,
        noProgramDescription,
        createNewProgram,
        headerStyle,
        programHeaderSection,
        headerSectionIcon,
        programProfileContainer,
        programInformation,
        createdProgram,
        fontStyle,
        admin,
        ownerImage,
        membershipInformationContainer,
        membershipHeader,
        membershipInformationItem,
        membershipInformationItemBorder,
        createProgramIcon,
        spinner,
        manageCommunicationsButton: [ButtonStyle, manageCommunicationsButton],
        programDetailButton: [ButtonStyle, programDetailButton],
        programDetailButtonSingle: [ButtonStyle, programDetailButtonSingle],
        manageCommunicationsButtonProd,
        programDetailButtonProd,
        membershipInformationContainerProd,
        mainContainer,
        srOnly: ["mch-screen-reader-only", srOnlyStyle]});
};
