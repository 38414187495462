import {
    mergeStyleSets,
    DefaultPalette,
    ICalloutContentStyleProps,
    IStyleFunctionOrObject,
    ICalloutContentStyles
} from 'office-ui-fabric-react';
import { NeutralColors } from '@cseo/styles';


export const callOutStyle: IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles> = {
    beak: {
        backgroundColor: DefaultPalette.themePrimary,
    },
}

export const getClassNames = () => {
    return mergeStyleSets({
        iconArea: {
            verticalAlign: 'middle',
            display: 'inline',
            height: "16px"
        },
        callout: {
            maxWidth: 300,
            fontSize: 12,
        },
        callOutPadding: {
            backgroundColor: DefaultPalette.themePrimary,
            color: NeutralColors.white,
            padding: "20px 24px 32px 24px"
        },
        unOrderedList: {
            paddingLeft: "18px",
            marginTop: "16px"
        },
        list: {
            listStyleType: "circle",
        }
    });
}