import { DefaultPalette, mergeStyleSets } from "@fluentui/react";
import { IStackItemStyles, IStackStyles, IStyle } from "office-ui-fabric-react";
const borderColor = "#E5E5E5"
export const accordionHeader: IStackItemStyles = {
    root: {
        background: DefaultPalette.neutralLighter,
        padding: '25px 10px 25px 10px',
        cursor: "pointer",
        backgroundColor: "white",
        borderBottom: `0px solid ${borderColor}`
    }
};
export const accordion: IStackStyles = {
    root: {
        border: `1px solid ${borderColor}`,
        backgroundColor: "white"
    }
};

export const accordionConent: IStackStyles = {
    root: {
        padding: 10
    }
};

const headerStyle: IStyle = {
    paddingLeft: 6,
    fontSize: 18,
    fontWeight: 500,
}

const iconStyle: IStyle = {
    width: 25,
    height: 30,
}

const savedIconStyle: IStyle = {
    color:"green"
}
export const getAccordionStyles = () => {
    return mergeStyleSets({
        headerStyle,
        iconStyle,
        savedIconStyle
    });
};