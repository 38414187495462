import { CoherencePanel, CoherencePanelSize } from '@cseo/controls';
import { DefaultButton, Link, PrimaryButton, TagItem, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { buttonStyles, getHierarchicalDataPickerStyles } from './HierarchicalDataPicker.styles';
import { HierarchyList } from './HierarchyList';
import { HierarchicalDataPickerResources } from './resources';

export const HierarchicalDataPicker = React.memo((props: HierarchicalDataPickerProps) => {
    const styles = getHierarchicalDataPickerStyles();
    const listRef = useRef<HierarchyListRef>(null);
    const { data } = props;

    const getSelectedItems = (list?: ParentItem[]) => {
        let selectedItems: ChildItem[] = [];
        list = list || props?.data;
        list?.forEach((parent) => {
            selectedItems = selectedItems.concat(parent.children.filter((child) => child.selected));
        });
        return selectedItems;
    };

    useEffect(() => {
        setViewState({ ...viewState, selectedItems: getSelectedItems() });
    }, [props?.data]);

    const [viewState, setViewState] = useState<HierarchicalDataPickerState>({
        isPanelOpen: false,
        selectedItems: getSelectedItems(),
    });

    const onChange = (modifiedChild: ChildItem) => {
        const index = viewState.selectedItems.findIndex((item) => item.id === modifiedChild.id);
        let updatedSelectedItems = [...viewState.selectedItems];
        // if item is selected and not exist in selected item list, add it.
        if (modifiedChild.selected === true && index === -1) {
            updatedSelectedItems.push(modifiedChild);
        } else if (modifiedChild.selected === false && index > -1) {
            // if item is not selected , but exists in list, remove it from state.
            updatedSelectedItems = viewState.selectedItems.filter((item) => item.id !== modifiedChild.id);
        } else {
            // if none of above condition matches, just, return so there will be no update of state and refresh of components .
            return;
        }

        setViewState({ ...viewState, selectedItems: updatedSelectedItems });
        props?.onChange(updatedSelectedItems);
    };

    const onRemoveItem = (child: ChildItem) => {
        const selectedItems = viewState.selectedItems.filter((item) => item.id !== child.id);
        setViewState({ ...viewState, selectedItems });
        listRef.current.clearItemSelection(child);
        props?.onChange(selectedItems);
    };

    const clearAll = () => {
        setViewState({ ...viewState, selectedItems: [] });
        listRef.current.clearAll();
        props?.onChange([]);
    };

    const onRenderHeader = () => {
        return (
            <div className={styles.headerRoot}>
                <div className={styles.headerLabel}>{HierarchicalDataPickerResources.Header}</div>
                {viewState?.selectedItems?.length > 0 && (
                    <div className={styles.headerContent}>
                        <div
                            className={styles.selectdCountLabel}
                        >{`${viewState?.selectedItems?.length} ${HierarchicalDataPickerResources.SelectedText}:`}</div>
                        {viewState?.selectedItems?.map((item, index) => {
                            return (
                                <TagItem
                                    onRemoveItem={() => {
                                        onRemoveItem(item);
                                    }}
                                    item={{ key: item.id, name: item.name }}
                                    index={index}
                                >
                                    {item.name}
                                </TagItem>
                            );
                        })}
                        <Link onClick={() => clearAll()}>{HierarchicalDataPickerResources.ClearAllText}</Link>
                    </div>
                )}
            </div>
        );
    };

    const onRenderFooter = () => {
        return (
            <div>
                <PrimaryButton
                    onClick={() => {
                        setViewState({ ...viewState, isPanelOpen: false });
                    }}
                    styles={buttonStyles}
                >
                    {HierarchicalDataPickerResources.SaveButtonText}
                </PrimaryButton>
                <DefaultButton onClick={() => setViewState({ ...viewState, isPanelOpen: false })}>
                    {HierarchicalDataPickerResources.CancelButtonText}
                </DefaultButton>
            </div>
        );
    };

    const getSelectedItemsDisplayText = () => {
        const textArray = viewState?.selectedItems?.map((item) => item.name);
        return textArray?.join(',');
    };

    return (
        <div className={props.className}>
            <TextField
                errorMessage={props?.errorMessage}
                ariaLabel={HierarchicalDataPickerResources.disciplineLabel}
                readOnly
                value={getSelectedItemsDisplayText()}
                onClick={() => {
                    setViewState({ ...viewState, isPanelOpen: true });
                }}
                onKeyPress={() => {
                    setViewState({ ...viewState, isPanelOpen: true });
                }}
            ></TextField>
            <CoherencePanel
                isLightDismiss={true}
                onDismiss={() => setViewState({ ...viewState, isPanelOpen: false })}
                isOpen={viewState?.isPanelOpen}
                hasCloseButton={true}
                panelSize={CoherencePanelSize.medium}
                onRenderHeader={onRenderHeader}
                onRenderFooter={onRenderFooter}
            >
                <div className={styles.contentRoot}>
                    <HierarchyList ref={listRef} source={data} onChange={(modifiedChild) => onChange(modifiedChild)} />
                </div>
            </CoherencePanel>
        </div>
    );
});
