import {
    IFetchMyProgramsAction,
    ProgramActionType,
    MentoringTopicsActionType,
    fetchMyProgramsFailed,
    fetchMyProgramsSuccess,
    fetchMyMentoringTopicsSuccess,
    fetchMyMentoringTopicsFailed,
    IFetchMyMentoringTopics,
} from './ProgramList.actions';
import { IProgramCard, IProgramOwner } from './ProgramsList.types';
import { call, getContext, put, takeLatest, all, fork } from 'redux-saga/effects';

import { formatDateToString } from '../../../Shared/Utilities';
import { ProgramClient, DomainsClient, ProgramDetail, IMentoringTopicsModel } from '../../../Shared';

function* fetchMyPrograms(action: IFetchMyProgramsAction) {
    const programClient: ProgramClient = yield getContext('programClient');
    try {
        const getProgramCards = async () => {
            const results: ProgramDetail[] = await programClient.listPrograms();
            const programList = await Promise.all(
                results.map(async (result) => {
                    const { documentStatus, startDate, name, programId, iconUrl } = result;
                    let defaultImg = '';
                    if (iconUrl == undefined && defaultImg.length == 0){
                        defaultImg = await programClient.getProgramIconSasUrl('default');
                    }
                    if (iconUrl) {
                        const icon = await programClient.getProgramIconSasUrl(iconUrl.split('/')[4]);
                        return {
                            documentStatus,
                            startDate: formatDateToString(startDate),
                            name,
                            programId,
                            iconUrl: icon,
                            owners: result.owners.map((o) => {
                                return { id: o, name: '' } as IProgramOwner;
                            }),
                        } as IProgramCard;
                    } else {
                        return {
                            documentStatus,
                            startDate: formatDateToString(startDate),
                            name,
                            programId,
                            iconUrl: defaultImg,
                            owners: result.owners.map((o) => {
                                return { id: o, name: '' } as IProgramOwner;
                            }),
                        } as IProgramCard;
                    }
                })
            );
            return programList;
        };

        const programCards: IProgramCard[] = yield call(getProgramCards);
        yield put(fetchMyProgramsSuccess(programCards));
    } catch (ex) {
        yield put(fetchMyProgramsFailed(ex?.data?.errorMessage ?? 'Something went wrong. Please try again later.'));
    }
}
function* fetchMyMentoringTopics(action: IFetchMyMentoringTopics) {
    const domainClient: DomainsClient = yield getContext('domainClient');
    try {
        const getMentoringTopics = async () => {
            const results: IMentoringTopicsModel = await domainClient.getMentoringTopics();
            return results;
        };

        const topics: IMentoringTopicsModel = yield call(getMentoringTopics);
        yield put(fetchMyMentoringTopicsSuccess(topics));
    } catch (ex) {
        yield put(
            fetchMyMentoringTopicsFailed(ex?.data?.errorMessage ?? 'Something went wrong. Please try again later.')
        );
    }
}

export function* programsListSaga(): IterableIterator<{}> {
    yield takeLatest(ProgramActionType.FETCH_MY_PROGRAMS, fetchMyPrograms);
}

export function* mentoringTopicSaga(): IterableIterator<{}> {
    yield takeLatest(MentoringTopicsActionType.FETCH_MENTORING_TOPICS, fetchMyMentoringTopics);
}

export function* programsListSagas() {
    yield all([fork(programsListSaga), fork(mentoringTopicSaga)]);
}
