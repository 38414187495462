import * as React from 'react';

import {
    ActionButton,
    DocumentCard,
    DocumentCardActivity,
    DocumentCardPreview,
    DocumentCardTitle,
    FontIcon,
    IStackTokens,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
} from 'office-ui-fabric-react';
import { FeatureName, SubFeatureName } from '../../../Shared/Resources/TelemetryEvents';
import { IProgramCard, IProgramOwner } from './ProgramsList.types';
import { IProgramsListState, programsListStateSelector } from './ProgramsList.reducers';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { createProgramDocumentCard, getAdminPortalStyles, programDocumentCard } from './ProgramsList.styles';

import { AdminPortalResources } from '../resources';
import { LoadingStates } from '../../../Shared';
import { ProgramCardImage } from './ProgramCardImage';
import { ProgramSettings, ProgramStatusEnum } from '../../../Shared/Api/sdk.types';
import { User } from '@microsoft/microsoft-graph-types';
import { UserRoles } from '../../../Shared/Resources/Roles';
import { fetchMyPrograms, fetchMyMentoringTopics } from './ProgramList.actions';
import { isUserInAnyRole } from '../../../Shared/Hooks/useUser';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useMentoringComponentContext } from '../../../Shared/Hooks/useMentoringComponentContext';
import { useSelector } from 'react-redux';
import { MentoringProgramRegisteredCounts } from '../../../Shared/Api/sdk.types';
import { FeatureNames } from '../../../Shared/Resources/FeatureNames';
import { useIsUserInFeature } from '../../../Shared/Hooks/useIsUserInFeature';
import { Link } from '@fluentui/react';

const defaultProgramImage = AdminPortalResources.previewImage;

interface ProgramsListProps extends RouteComponentProps {}

export const ProgramsList = (props: ProgramsListProps) => {
    const styles = getAdminPortalStyles();
    const containerToken: IStackTokens = { padding: 50 };
    const { appInsightsClient } = useMentoringComponentContext();
    const dispatch = useDispatch();

    const onCreateNewProgramClick = () => {
        appInsightsClient.logButtonClick(FeatureName.AdminPortal, SubFeatureName.NewProgram);
        props?.history?.push('create-program');
    };
    const programListState: IProgramsListState = useSelector(programsListStateSelector);

    useEffect(() => {}, [programListState?.programs]);
    useEffect(() => {
        // TODO: need to move this some boostrap location.
        dispatch(fetchMyPrograms());
        dispatch(fetchMyMentoringTopics());
    }, []);

    const onRefreshClick = () => {
        appInsightsClient.logButtonClick(FeatureName.AdminPortal, SubFeatureName.Refresh);
        dispatch(fetchMyPrograms());
        dispatch(fetchMyMentoringTopics());
    };

    return (
        <Stack className={styles.mainContainer}>
            {/* Header */}
            <Stack
                horizontal
                verticalAlign="baseline"
                horizontalAlign="space-between"
                wrap
                style={{ paddingBottom: 50 }}
            >
                <h1>{AdminPortalResources.title}</h1>
                <Stack horizontal wrap>
                    <ActionButton
                        iconProps={{ iconName: 'Refresh' }}
                        onClick={() => {
                            onRefreshClick();
                        }}
                    >
                        {AdminPortalResources.refresh}
                    </ActionButton>
                    {isUserInAnyRole([UserRoles.SuperAdmin]) === true && (
                        <ActionButton
                            iconProps={{ iconName: 'Add' }}
                            onClick={() => {
                                onCreateNewProgramClick();
                            }}
                            id={AdminPortalResources.newProgram}
                            aria-label={AdminPortalResources.newProgram}
                        >
                            {AdminPortalResources.newProgram}
                        </ActionButton>
                    )}
                </Stack>
            </Stack>

            {programListState?.programFetchOrUpdateStatus === LoadingStates.STARTED && (
                <Spinner
                    label="Loading..."
                    ariaLive="polite"
                    className={styles.spinner}
                    labelPosition="right"
                    size={SpinnerSize.small}
                />
            )}
            {programListState?.programFetchOrUpdateStatus === LoadingStates.SUCCEEDED &&
                programListState?.programs?.length == 0 && (
                    <Stack horizontalAlign="center">
                        <ProgramCardImage />
                        <h1 className={styles.noProgramHeader}>{AdminPortalResources.noProgram}</h1>
                        {isUserInAnyRole([UserRoles.SuperAdmin]) === true && (
                            <>
                                <p className={styles.noProgramDescription}>{AdminPortalResources.createFirstProgram}</p>
                                <PrimaryButton
                                    text={AdminPortalResources.createNewProgram}
                                    className={styles.createNewProgram}
                                    onClick={onCreateNewProgramClick}
                                />
                            </>
                        )}
                    </Stack>
                )}
            {programListState?.programFetchOrUpdateStatus === LoadingStates.SUCCEEDED &&
                programListState?.programs?.length > 0 && (
                    <Stack horizontal wrap>
                        {programListState?.programs.map((programItem, index) => (
                            <>
                                <ProgramCard program={programItem} />
                                {index === programListState?.programs?.length - 1 &&
                                    isUserInAnyRole([UserRoles.SuperAdmin]) === true && (
                                        <DocumentCard
                                            styles={createProgramDocumentCard}
                                            onClick={onCreateNewProgramClick}
                                        >
                                            <FontIcon iconName="Add" className={styles.createProgramIcon} />
                                            <DocumentCardTitle title={AdminPortalResources.creatMentoringProgram} />
                                        </DocumentCard>
                                    )}
                            </>
                        ))}
                    </Stack>
                )}
        </Stack>
    );
};

interface ProgramCardProps {
    program: IProgramCard;
}
export const ProgramCard = (props: ProgramCardProps) => {
    const [program, setProgram] = React.useState(props?.program);
    const [programSetting, setProgramSetting] = React.useState<ProgramSettings>(undefined);
    const [adminDetails, setAdminDetails] = React.useState<User[]>([]);
    const [registeredCount, setRegisteredCounts] = React.useState<MentoringProgramRegisteredCounts>(undefined);
    const styles = getAdminPortalStyles();
    const { appInsightsClient, graphService, programService } = useMentoringComponentContext();
    const history = useHistory();
    const notificationPageFeature = useIsUserInFeature(FeatureNames.NOTIFICATIONPAGE);
    const adminDashboardFeature = useIsUserInFeature(FeatureNames.ADMINDASHBOARD);
    const onProgramDetailsClick = (programId: string) => {
        appInsightsClient.logButtonClick(FeatureName.AdminPortal, SubFeatureName.ProgramDetails);
        history?.push(`programs/${programId}`);
    };
    const reportLink = React.useRef(null);

    const onEmailNotificationClick = (id: string) => {
        appInsightsClient.logButtonClick(FeatureName.AdminPortal, SubFeatureName.ProgramDetails);
        history?.push(`notification/${id}`);
    };

    useEffect(() => {
        const updateOwnerPics = async (program: IProgramCard) => {
            let usersIds: string[] = [];
            const updatedOwners = await Promise.all(
                program?.owners.map(async (owner) => {
                    usersIds.push(owner.id);
                    return {
                        ...owner,
                        profileImageSrc: await graphService.getPhotoForResource(owner.id),
                    } as IProgramOwner;
                })
            );
            if (usersIds.length > 0) {
                const admins = await graphService.getUsersForUserIds(usersIds);
                setAdminDetails(admins);
            }
            program.iconUrl = program.iconUrl === null ? defaultProgramImage : program.iconUrl;
            setProgram({ ...program, owners: [...updatedOwners] });
        };

        const updateProgramSetting = async (program: IProgramCard) => {
            const programSetting = await programService.getProgramSetting(program.programId);
            setProgramSetting(programSetting);
        };
        updateOwnerPics(props.program);
        updateProgramSetting(props.program);
    }, [props.program]);

    useEffect(() => {
        const fetchMentoringProgramRegisteredCounts = async () => {
            const registeredCountResult = await programService.getMentoringProgramRegisteredCounts(program.programId);
            registeredCountResult && setRegisteredCounts(registeredCountResult);
        };
        if (registeredCount == undefined) {
            fetchMentoringProgramRegisteredCounts();
        }
    }, []);

    const updatedImage = {
        previewImages: [
            {
                previewImageSrc: program?.iconUrl,
                width: 72,
                height: 72
            },
        ],
    };

    const getAdminNames = (): string => {
        const firstTwoAdminNames = adminDetails.slice(0, 2);
        const additionalAdmin = adminDetails.length > 1 ? ` + ${adminDetails.length - 1} more` : '';
        const firstAdminNames = firstTwoAdminNames.map((admin) => admin.displayName).join(', and ');
        return firstAdminNames + additionalAdmin;
    };

    const OnViewReportsClick = () =>  {
        reportLink.current.click();
    }

    const trackingTelemetryViewReports = () => {
        appInsightsClient.logLinkClick(
            FeatureName.AdminPortal,
            SubFeatureName.LinkToAdminDashboard_ViewReports,
            {ProgramId: program.programId}
        );
    }

    return (
        <DocumentCard className={styles.documentCard} styles={programDocumentCard} key={program.programId}>
            <div className={styles.programHeaderSection}>
                <DocumentCardTitle title={program.name} shouldTruncate className={styles.headerStyle} />
            </div>
            <Stack horizontal className={styles.programProfileContainer}>
                <DocumentCardPreview {...updatedImage} />
                <Stack className={styles.programInformation}>
                    <p className={styles.createdProgram}>Created: {program.startDate}</p>
                    <p className={styles.fontStyle}>Status: {programSetting && programSetting.programStatus}</p>
                    <Stack horizontal verticalAlign="center" className={styles.admin}>
                        <p aria-hidden="true" className={styles.fontStyle}>
                            Program Leads:
                        </p>
                        <p className={styles.srOnly}>{`Program Leads: ${getAdminNames()}`}</p>
                        <div aria-hidden="true">
                            <DocumentCardActivity activity="" people={program.owners} className={styles.ownerImage} />
                        </div>
                    </Stack>
                </Stack>
            </Stack>

            <Stack className={adminDashboardFeature ? styles.membershipInformationContainer : styles.membershipInformationContainerProd}>
                <div role="heading" aria-level={2} className={styles.membershipHeader}>
                    {AdminPortalResources.membershipInformation}
                </div>
                <Stack className={styles.membershipInformationItem}>
                    <div className={styles.membershipInformationItemBorder}>
                        <strong>{registeredCount?.registeredMenteeCount || 0}</strong>
                        <p>{AdminPortalResources.menteesActive}</p>
                    </div>
                </Stack>
                <Stack className={styles.membershipInformationItem}>
                    <div className={styles.membershipInformationItemBorder}>
                        <strong>{registeredCount?.registeredMentorCount || 0}</strong>
                        <p>{AdminPortalResources.mentorsActive}</p>
                    </div>
                </Stack>
                <Stack className={styles.membershipInformationItem}>
                    <div className={styles.membershipInformationItemBorder}>
                        <strong>{registeredCount?.activeMentorshipCount || 0}</strong>
                        <p>{AdminPortalResources.mentorshipPairings}</p>
                    </div>
                </Stack>
            </Stack>
            {adminDashboardFeature ? (
                <>
                {programSetting?.programStatus == ProgramStatusEnum.Active ? (
                <Stack wrap>
                    <Stack horizontal wrap>
                        <PrimaryButton
                            onClick={() => onProgramDetailsClick(program.programId)}
                            text={AdminPortalResources.editProgram}
                            className={styles.programDetailButton}
                            ariaLabel={program.name + ' ' + AdminPortalResources.editProgram}
                        />
                        <PrimaryButton
                            text={AdminPortalResources.programReport}
                            className={styles.programDetailButton}
                            ariaLabel={program.name + ' ' + AdminPortalResources.programReport}
                            onClick={() => OnViewReportsClick()}
                        />
                        <Link hidden ref={reportLink} onClick={() => trackingTelemetryViewReports()} href={`https://msit.powerbi.com/groups/me/reports/eb31ca91-c07d-4fd1-9902-b924d44b7c2f/ReportSectionfb39b1dbc7e4ea5578f3?filter=dimProgramDetails%2Fname%20eq%20%27${program.name}%27`} role="link" target="_blank"/>
                    </Stack>
                    <PrimaryButton
                        onClick={() => onEmailNotificationClick(program.programId)}
                        text={AdminPortalResources.manageCommunications}
                        className={styles.manageCommunicationsButton}
                        ariaLabel={program.name + ' ' + AdminPortalResources.manageCommunications}
                    />
                </Stack>
                ) : (
                    <PrimaryButton
                        onClick={() => onProgramDetailsClick(program.programId)}
                        text={AdminPortalResources.programDetails}
                        className={styles.programDetailButtonSingle}
                        ariaLabel={program.name + ' ' + AdminPortalResources.programDetails}
                    />
                )}
                </>
            ) : ( 
                <>
                {notificationPageFeature && programSetting?.programStatus == ProgramStatusEnum.Active ? (
                <Stack horizontal wrap>
                    <PrimaryButton
                        onClick={() => onProgramDetailsClick(program.programId)}
                        text={AdminPortalResources.editProgram}
                        className={styles.programDetailButtonProd}
                        ariaLabel={program.name + ' ' + AdminPortalResources.editProgram}
                    />
                    <PrimaryButton
                        onClick={() => onEmailNotificationClick(program.programId)}
                        text={AdminPortalResources.manageCommunications}
                        className={styles.manageCommunicationsButtonProd}
                        ariaLabel={program.name + ' ' + AdminPortalResources.manageCommunications}
                    />
                </Stack>
                ) : (
                    <PrimaryButton
                        onClick={() => onProgramDetailsClick(program.programId)}
                        text={AdminPortalResources.programDetails}
                        className={styles.programDetailButtonProd}
                        ariaLabel={program.name + ' ' + AdminPortalResources.programDetails}
                    />
                )}
                </>
            )}
        </DocumentCard>
    );
};
