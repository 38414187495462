// <reference path="./OfficeBrowserFeedback.d.ts"/>
import { useMentoringComponentContext } from '../Shared/Hooks/useMentoringComponentContext';
import { ActionName, FeatureName } from '../Shared/Resources/TelemetryEvents';
import campaignDefinitions from './CampaignDefinitions';
import { TelemetrySinkProvider } from './logging/TelemetrySinkProvider';

let floodgateInterval: number | undefined

function isOCVLoaded(): boolean {
    return !!window.OfficeBrowserFeedback && !!window.OfficeBrowserFeedback.multiFeedback ? true : false;
}

export function loadFloodgateScript(): Promise<void> {
    return new Promise((resolve: Function, reject: OnErrorEventHandlerNonNull): void => {
        if (isOCVLoaded()) return resolve();
        const script: HTMLScriptElement = document.createElement('script');
        script.onload = (): void => {
            resolve();
        };
        script.onerror = reject;
        script.src = '/ocv/scripts/officebrowserfeedback_floodgate.min.js';
        document.body.appendChild(script);
    });
}

class OfficeBrowserFeedbackWrapper {

    //Reference the class structure in "node_modules\@ms-ofb\officebrowserfeedback\typings\OfficeBrowserFeedback.d.ts"
    public async init(sinkClient: TelemetrySinkProvider, email?:string| undefined): Promise<void> {
        (<any>window).OfficeBrowserFeedback = (<any>window).OfficeBrowserFeedback || {};
        (<any>window).OfficeBrowserFeedback.floodgate = (<any>window).OfficeBrowserFeedback.floodgate || {};
        (<any>window).OfficeBrowserFeedback.initOptions = {
            appId: __OCV__,// INT and Production have different production tenants
            stylesUrl: "ocv/styles/officebrowserfeedback.min.css",
            intlUrl: "ocv/intl/",
            environment: 0, // 1 is for test and 0 is for Production   
            userEmail:email,
            telemetrySink: sinkClient.getTelemetrySink(),
        };
        (<any>window).OfficeBrowserFeedback.floodgate.initOptions = {
            autoDismiss: 0, // will automatically dismiss the survey if not clicked after 7 * x seconds (currently 21s)
            campaignDefinitions: campaignDefinitions,
        };

        await (<any>window).OfficeBrowserFeedback.floodgate.initialize();
        //appLogger.logInfo(userActionNames.SurveyIntialized, {});
        await (<any>window).OfficeBrowserFeedback.floodgate.start();
        setFloodgateInterval();

        /*
        * Need to start and stop the floodgate as it should only be in the 'start' state while the window is in focus
        * We also set and clear the interval accordingly so that we ONLY trigger survey activity in the 'start' state
        */

        (<any>window).onfocus = () => {
            if ((<any>window).OfficeBrowserFeedback.floodgate) {
                (<any>window).OfficeBrowserFeedback.floodgate.start();
                setFloodgateInterval();
            }
        };

        (<any>window).onblur = () => {
            if ((<any>window).OfficeBrowserFeedback.floodgate) {
                clearFloodgateInterval();
                (<any>window).OfficeBrowserFeedback.floodgate.stop();
            }
        };

        (<any>window).onunload = () => {
            if ((<any>window).OfficeBrowserFeedback.floodgate) {
                clearFloodgateInterval();
                (<any>window).OfficeBrowserFeedback.floodgate.stop();
            }
        };
    }

}
function setFloodgateInterval(): void {
    floodgateInterval = floodgateInterval || (<any>window).setInterval(triggerSurveyActivity, 1000);
}

/**
 * Stops survey activity timer
 * We don't want to trigger the timer when we are not in focus as the survey will only appear when we are in focus
 */
function clearFloodgateInterval(): void {
    (<any>window).clearInterval(floodgateInterval);
    floodgateInterval = undefined;
}

/**
 * The function ran after every second after a timer is set
 * This does this actual triggering of the survey activity
 */
function triggerSurveyActivity(): void {
    (<any>window).OfficeBrowserFeedback.floodgate.getEngine().getActivityListener().logActivity('SurveyActivityTriggered');
    // 'SurveyActivityTriggered' based on CampaignDefinitions
}

export const ocvFloodgateFeedback = new OfficeBrowserFeedbackWrapper();