import { HttpClient } from '@employee-experience/core';
import {
    EmailNotificationClient,
    ProgramServiceModuleApiException,
    EmailType2,
    AudienceTypeEnum2,
    QueueEmailRequest,
    SendTestEmailRequest
} from '../Api/sdk.types';

export class EmailNotificationService {
    private readonly EmailNotificationClient: EmailNotificationClient;

    public constructor(EmailNotificationClient: EmailNotificationClient) {
        this.EmailNotificationClient = EmailNotificationClient;
    }

  

    public async getDeltaAudienceCount(programId: string | null, emailType: EmailType2, audienceType: AudienceTypeEnum2) {
        try {
            return await this.EmailNotificationClient.getDeltaAudienceCount(programId, emailType, audienceType);
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    }

    public async queueEmailNotification(request: QueueEmailRequest) {
        try {
            return await this.EmailNotificationClient.queueEmailNotificationRequest(request);
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    }

    public async sendTestEmailNotification(request: SendTestEmailRequest) {
        try {
            return await this.EmailNotificationClient.sendTestEmailNotification(request);
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    }
    public async programHasQueuedEmailJobForTemplate(programId: string | null, templateId: string | null) {
        try {
            return await this.EmailNotificationClient.programHasQueuedEmailJobForTemplate(programId, templateId);
        } catch (exp) {
            if ((exp as ProgramServiceModuleApiException).status == 404) {
                return null;
            }
            throw exp;
        }
    }
}
