import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { Announced } from 'office-ui-fabric-react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { LoadingStates } from '../../../Shared';
import { CommunicationTemplate } from '../../../Shared/Api/sdk.types';
import { useMentoringComponentContext } from '../../../Shared/Hooks/useMentoringComponentContext';
import { UserContext } from '../../../Shared/Hooks/useUser';
import { FeatureName, SubFeatureName } from '../../../Shared/Resources/TelemetryEvents';
import { StatusMessageBar } from '../../../Shared/StatusMessageBar/StatusMessageBar';
import { EmailTemplateResources } from '../resources';
import { EmailTemplate } from './EmailTemplate';
import { EmailTemplateFooter } from './EmailTemplateFooter';
import { getProgramNotificationStyle } from './ProgramNotifications.styles';

const styles = getProgramNotificationStyle();

interface ProgramNotificationProps {
    template: CommunicationTemplate;
    originalTemplate: CommunicationTemplate;
    onParameterChange: (newTemplate: CommunicationTemplate) => void;
    index: number;
    onSaveClick: () => void;
    programId: string;
    onCancelled: () => void;
    checkForChanges: boolean;
}

export enum ISendEmailType {
    SendEmail,
    SendTestEmail,
}

export const ProgramNotification = (props: ProgramNotificationProps) => {
    const [editModeAcitvated, setEditModeAcitvated] = useState<boolean>(false);
    const [sentEmailType, setSentEmailType] = useState<ISendEmailType>(undefined);
    const [showDialog, toggleShowDialog] = useState<boolean>(true);
    const [audienceCount, setAudienceCount] = useState<number>(undefined);
    const [hasQueuedEmailJob, setHasQueuedEmailJob] = useState<boolean>(false);
    const [loadingState, setLoadingState] = React.useState(LoadingStates.NOT_STARTED);
    const [announced, setAnnounced] = React.useState<JSX.Element | undefined>(undefined);
    const { template, onParameterChange, programId, checkForChanges } = props;
    const { emailNotificationService, appInsightsClient } = useMentoringComponentContext();
    const [failureMessage, setFailureMessage] = useState<string>('');

    useEffect(() => {
        const getDeltaAudienceCount = async () => {
            const count = await emailNotificationService.getDeltaAudienceCount(
                programId,
                template.emailType,
                template.audience
            );
            setAudienceCount(count);
        };

        const programHasQueuedEmailJobForTemplate = async () => {
            const count = await emailNotificationService.programHasQueuedEmailJobForTemplate(
                programId,
                template.templateId
            );
            setHasQueuedEmailJob(count);
        };
        if (template.emailType && template.audience) {
            getDeltaAudienceCount();
        }
        if (template.templateId) {
            programHasQueuedEmailJobForTemplate();
        }
    }, []);

    useEffect(() => {
        setEditModeAcitvated(false);
    }, [props.originalTemplate]);

    const onEditClick = () => {
        appInsightsClient.logButtonClick(
            FeatureName.Notification,
            SubFeatureName.Notification_EditEmail,
            { templateId: template.templateId, programId: programId}
          );
        setEditModeAcitvated(!editModeAcitvated);
    };

    const onCancelClick = () => {
        props.onCancelled();
        setEditModeAcitvated(!editModeAcitvated);
    };

    const onSaveClicked = () => {
        props.onSaveClick();
        setEditModeAcitvated(!editModeAcitvated);
    };

    const toggleDialog = (type?: ISendEmailType) => {
        setAnnounced(<Announced 
            message='Email Dialog box opened' aria-live="assertive" />)
        toggleShowDialog(!showDialog);
        setSentEmailType(type);
    };

    const onDialogSendEmail = async () => {

        appInsightsClient.logButtonClick(
            FeatureName.Notification,
            SubFeatureName.Notification_SendEmail,
            { templateId: template.templateId, programId: programId}
        );
        setLoadingState(LoadingStates.STARTED);
        try {
            if (template) {
                await emailNotificationService.queueEmailNotification({
                    programId: props.programId,
                    audienceType: template.audience as any,
                    emailType: template.emailType as any,
                    templateId: template.templateId,
                    timestamp: new Date(),
                });
                setHasQueuedEmailJob(true);
                setLoadingState(LoadingStates.SUCCEEDED);
            }
        } catch {
            setHasQueuedEmailJob(false);
            setLoadingState(LoadingStates.FAILED);
            setFailureMessage('Unable to queue email. Please try again later');
        }
        toggleShowDialog(!showDialog);
    };

    const onDialogSendTestEmail = async (sender: string) => {
        appInsightsClient.logButtonClick(
            FeatureName.Notification,
            SubFeatureName.Notification_SendTestEmail,
            { templateId: template.templateId, programId: programId}
        );
        try {
            if (template) {
                // TO DO : pass email and audience type after  EmailType2 and AudienceType2 interface is fixed in API
                await emailNotificationService.sendTestEmailNotification({
                    programId: props.programId,
                    audienceType: template.audience as any,
                    emailType: template.emailType as any,
                });
            }
        } catch(exception) {
            setFailureMessage('Unable to send test email. Please try again later');
        }
        toggleDialog();
    };

    const dialogContentProps = {
        type: DialogType.normal,
        title: `${EmailTemplateResources.readyToSendText}  ${template.description} ?`,
        closeButtonAriaLabel: 'Close',
        subText:
            sentEmailType == ISendEmailType.SendTestEmail
                ? EmailTemplateResources.sendTestEmailContent
                : EmailTemplateResources.sendEmailContent(audienceCount),
    };

    const handleOnLinkClick = () => {
        appInsightsClient.logLinkClick(
          FeatureName.Notification,
          SubFeatureName.Notification_TemplateLink,
          { templateId: template.templateId, programId: programId } 
        );
      };

    return (
        <>
        {announced}
        <UserContext.Consumer>
            {(user) => (
                <div>
                    {loadingState === LoadingStates.SUCCEEDED && (
                        <div className={styles.messageBarContanier}>
                            <StatusMessageBar
                                message={EmailTemplateResources.successfullyQueuedMessage}
                                messageBarType={MessageBarType.success}
                                autoHideInMillSec={4000}
                            ></StatusMessageBar>
                        </div>
                    )}
                    {loadingState === LoadingStates.FAILED && (
                        <div className={styles.messageBarContanier}>
                            <StatusMessageBar
                                message={EmailTemplateResources.failedQueuedMessage + failureMessage}
                                messageBarType={MessageBarType.error}
                                autoHideInMillSec={4000}
                            ></StatusMessageBar>
                        </div>
                    )}
                    {hasQueuedEmailJob && (
                        <div className={styles.messageBarContanier}>
                            <MessageBar
                                messageBarType={MessageBarType.info}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                            >
                                {EmailTemplateResources.queuedMessageStatus}
                            </MessageBar>
                        </div>
                    )}
                    <EmailTemplate
                        template={template}
                        editMode={editModeAcitvated}
                        onParameterChange={onParameterChange}
                        onTemplateLinkClick= {handleOnLinkClick}
                    />

                    <EmailTemplateFooter
                        audienceCount={audienceCount}
                        editMode={editModeAcitvated}
                        onSaveClick={onSaveClicked}
                        onSendEmail={() => toggleDialog(ISendEmailType.SendEmail)}
                        onSendTestEmail={() => toggleDialog(ISendEmailType.SendTestEmail)}
                        onEditClick={onEditClick}
                        onCancelClick={onCancelClick}
                        emailType={template.emailType}
                        isSaveDraftDisabled={checkForChanges}
                        isSendEmailDisabled={loadingState == LoadingStates.STARTED || hasQueuedEmailJob}
                    />

                    <Dialog
                        hidden={showDialog}
                        onDismiss={() => toggleShowDialog(true)}
                        dialogContentProps={dialogContentProps}
                        minWidth={387}
                        maxWidth={500}
                    >
                        <DialogFooter>
                            {sentEmailType == ISendEmailType.SendEmail && (
                                <PrimaryButton onClick={() => {
                                        onDialogSendEmail();
                                    setAnnounced(<Announced message='email is being sent dialog box closed' aria-live="assertive" />)
                                }} text={EmailTemplateResources.sendEmail} />
                            )}
                            {sentEmailType == ISendEmailType.SendTestEmail && (
                                <DefaultButton
                                    onClick={() => {
                                        onDialogSendTestEmail(user.email)
                                        setAnnounced(<Announced message='test email is being sent dialog box closed' aria-live="assertive" />)
                                    }}
                                    text={EmailTemplateResources.sendTestEmail}
                                />
                            )}
                            <DefaultButton onClick={() => {
                                appInsightsClient.logButtonClick(
                                    FeatureName.Notification,
                                    SubFeatureName.Notification_NotYet,
                                    { templateId: template.templateId, programId: programId}
                                );
                                toggleDialog();
                                setAnnounced(<Announced 
                                    message={sentEmailType == ISendEmailType.SendTestEmail
                                        ? 'closed Test Email Dialog box'
                                        : 'closed email Dialog box'} 
                                    aria-live="assertive" />)
                            }} text={EmailTemplateResources.notYet} />
                        </DialogFooter>
                    </Dialog>
                </div>
            )}
        </UserContext.Consumer>
        </>
    );
};
