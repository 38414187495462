import { Announced, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';

export interface StatusMessageBarProps {
    messageBarType: MessageBarType;
    autoHideInMillSec?: number;
    message: string;
    showDismiss?: boolean;
}

export const StatusMessageBar = (props: StatusMessageBarProps) => {
    const [isMessageVisible, setIsMessageVisible] = useState(true);
    useEffect(() => {
        props?.autoHideInMillSec &&
            setTimeout(function () {
                setIsMessageVisible(false);
            }, props?.autoHideInMillSec);
    }, []);

    return (
        <>
            {isMessageVisible && (
                <>
                    <Announced role="alert" aria-live="assertive" message={props.message} />{' '}
                    <MessageBar
                        onDismiss={() => {
                            setIsMessageVisible(false);
                        }}
                        messageBarType={props?.messageBarType}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                    >
                        {props.message}
                    </MessageBar>
                </>
            )}
        </>
    );
};
