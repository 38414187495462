/**
 * Class that holds constants used in Flight Feature Names
 */
export class FeatureNames {
    public static EXECSPONSOR: string = 'ExecSponsor';
    public static NOTIFICATIONPAGE: string = 'NotificationPage';
    public static ADMINCOMMUNICATIONS: string = 'AdminCommunications';
    public static TESTEMAIL: string = 'TestEmail';
    public static ADMINDASHBOARD: string = 'AdminDashboard';
}
