import { FontWeights, IStyle, mergeStyleSets } from 'office-ui-fabric-react';
import { IProgramStepState, IProgramTrackerStepProps } from './ProgramTracker.types';

const stepContainer: IStyle = {
    display: 'flex',
    alignItems: 'center',
};

const connector: IStyle = {
    width: '2px',
    height: '36px',
    backgroundColor: '#949494',
    marginTop: '4px',
    marginBottom: '4px',
    marginLeft: '7px',
    selectors: {
        ':last-child': {
            display: 'none',
        },
    },
};

const stepIcon: IStyle = { color: '#FFFFFF', margin: 'auto', fontSize: '12px' };

const stepLabel: IStyle = { fontSize: '14px' };

const step = (state: IProgramStepState) => {
    let stepStyle: IStyle = {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        marginRight: '12px',
        display: 'flex',
        selectors: {
            '@media (forced-colors: active)': {
                forcedColorAdjust: 'none'
            }
        },
    };

    switch (state) {
        case IProgramStepState.Active:
        case IProgramStepState.Completed:
            stepStyle.backgroundColor = '#0078D4';
            break;
        case IProgramStepState.Error:
            stepStyle.backgroundColor = '#E0181A';
            break;
        default:
            stepStyle.border = '2px solid #949494;';
            stepStyle.backgroundColor = 'none';
            break;
    }

    return stepStyle;
};

export const getProgramTrackerStepStyles = (
    props: IProgramTrackerStepProps
): {
    stepContainer: string;
    step: string;
    stepIcon: string;
    connector: string;
    stepLabel: string;
} => {
    return mergeStyleSets({
        stepContainer,
        step: [step(props?.state)],
        stepIcon,
        connector,
        stepLabel: [stepLabel, props?.state === IProgramStepState.Active && { fontWeight: FontWeights.bold }],
    });
};

const programTrackerPanelHeader: IStyle = {
    fontSize: '14px',
    fontWeight: FontWeights.bold,
    marginBottom: '20px',
};

export const getProgramTrackerPanelStyles = (): {   
    programTrackerPanelHeader: string;
} => {
    return mergeStyleSets({      
        programTrackerPanelHeader,
    });
};
