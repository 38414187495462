import React from 'react';

export const ProgramCardImage = () => (
    <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                d="M126.617 160.732L41.9165 179.729C35.9942 181.056 30.1298 177.316 28.8062 171.388L0.266872 43.4862C-1.05672 37.5479 2.67252 31.6677 8.58521 30.3405L93.2854 11.334C99.2077 10.0068 105.072 13.7461 106.396 19.6748L134.925 147.586C136.259 153.524 132.529 159.405 126.617 160.732Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M128.688 162.282L41.8909 161.846C35.8236 161.817 30.935 156.857 30.964 150.783L31.621 19.7136C31.6499 13.6299 36.5965 8.72815 42.6541 8.75721L129.451 9.19314C135.518 9.2222 140.407 14.1821 140.378 20.2561L139.721 151.325C139.692 157.409 134.745 162.311 128.688 162.282Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M146.329 165.159L60.6434 151.267C54.6534 150.299 50.586 144.641 51.5521 138.645L72.4108 9.25127C73.3769 3.24513 79.019 -0.833227 84.9994 0.135504L170.685 14.0271C176.675 14.9958 180.742 20.6532 179.776 26.6497L158.918 156.043C157.951 162.049 152.309 166.128 146.329 165.159Z"
                fill="#A7CBF6"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="104.901"
                y1="135.578"
                x2="1.87746"
                y2="25.5761"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.000351356" stop-color="#2970B8" />
                <stop offset="0.1812" stop-color="#397DC4" />
                <stop offset="1" stop-color="#619EE4" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="85.6707"
                y1="10.5918"
                x2="85.6707"
                y2="159.43"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#72ACF1" />
                <stop offset="1" stop-color="#5B99DF" />
            </linearGradient>
            <clipPath id="clip0">
                <rect width="180" height="180" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
