import cloneDeep from 'lodash.clonedeep';
import { Announced, CommandButton, IconButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { LogicalOperator, RuleTypeEnum, TargetingRule } from '../../../../Shared';
import { ConditionComposer, ConditionComposerRef } from './ConditionComposer';
import { getConditionGroupComposerStyles } from './ConditionGroupComposer.styles';
import { ConditionGroupOperatorSelector } from './ConditionGroupOperatorSelector';

const Resources = {
    AddRule: 'Add Rule',
    AddGroup: 'Add Group',
};

export const ConditionGroupComposer = React.forwardRef(
    (
        props: {
            conditionGroup?: TargetingRule;
            isRootComposer?: boolean;
            onConditionGroupUpdate: (updatedConditionGroup: TargetingRule) => void;
            onDeleteGroup?: () => void;
        },
        ref: React.Ref<ConditionComposerRef>
    ) => {
        const [announced, setAnnounced] = React.useState<JSX.Element | undefined>(undefined);
        const refs = {} as any;

        const createRefs = () => {
            props?.conditionGroup?.conditions?.forEach((c, index) => {
                const refId: string = c.ruleType === RuleTypeEnum.RuleGroup ? `${index}-group` : `${index}-condition`;
                refs[refId] = React.createRef<ConditionComposerRef>();
            });
        };

        createRefs();

        React.useImperativeHandle(ref, () => ({
            validate: () => {
                Object.keys(refs).forEach((k) => {
                    refs[k]?.current?.validate();
                });
            },
        }));

        const addCondition = (index?: number) => {
            const updatedConditionGroup = cloneDeep(props?.conditionGroup);
            let newCondition: TargetingRule = {
                compareValue: [],
                conditionType: null,
                propertyType: null,
                ruleType: RuleTypeEnum.SingleCondition,
            };

            if (index === null || index == undefined || index === props?.conditionGroup?.conditions?.length) {
                updatedConditionGroup.conditions.push(newCondition);
            } else {
                updatedConditionGroup.conditions.splice(index, 0, newCondition);
            }

            props?.onConditionGroupUpdate(updatedConditionGroup);
            setAnnounced(<Announced message={`Rule Condition Row ${index + 1} Added`} aria-live="assertive" />);
        };

        const removeCondition = (index: number) => {
            const updatedConditionGroup = cloneDeep(props?.conditionGroup);
            updatedConditionGroup.conditions.splice(index, 1);
            props?.onConditionGroupUpdate(updatedConditionGroup);
            setAnnounced(<Announced message={`Rule Condition Row ${index + 1} Removed`} aria-live="assertive" />);
        };

        const handleOperatorChange = (joinType: LogicalOperator) => {
            const updatedConditionGroup = cloneDeep(props?.conditionGroup);
            updatedConditionGroup.joinType = joinType;
            props?.onConditionGroupUpdate(updatedConditionGroup);
        };

        const onTargetingRuleUpdate = (modifiedConditionGroup: TargetingRule, index: number) => {
            const updatedConditionGroup = cloneDeep(props?.conditionGroup);
            if (index < updatedConditionGroup.conditions.length && updatedConditionGroup.conditions[index]) {
                updatedConditionGroup.conditions[index] = modifiedConditionGroup;
            }
            props?.onConditionGroupUpdate(updatedConditionGroup);
        };

        const styles = getConditionGroupComposerStyles(props?.isRootComposer);

        return (
            <div className={styles.root}>
                {announced}
                <div className={styles.groupCommandBar}>
                    <ConditionGroupOperatorSelector
                        isAtRootComposer={props?.isRootComposer}
                        operator={props?.conditionGroup?.joinType || LogicalOperator.And}
                        onOperatorChange={(operator) => handleOperatorChange(operator)}
                    />
                    {props?.isRootComposer !== true && (
                        <CommandButton iconProps={{ iconName: 'Add' }} onClick={() => addCondition()} aria-label={Resources.AddRule}>
                            {Resources.AddRule}
                        </CommandButton>
                    )}
                    {props?.isRootComposer !== true && (
                        <IconButton
                            ariaLabel={'Delete'}
                            iconProps={{ iconName: 'Delete' }}
                            style={{ marginLeft: 'auto' }}
                            onClick={() => props?.onDeleteGroup()}
                        ></IconButton>
                    )}
                </div>
                {props?.conditionGroup?.conditions?.map((expresion, index) => {
                    return expresion.ruleType === RuleTypeEnum.RuleGroup ? (
                        <ConditionGroupComposer
                            ref={refs[`${index}-group`]}
                            key={`${index}-group`}
                            conditionGroup={expresion}
                            onDeleteGroup={() => removeCondition(index)}
                            onConditionGroupUpdate={(updatedConditionGroup) => {
                                onTargetingRuleUpdate(updatedConditionGroup, index);
                            }}
                        />
                    ) : (
                        <ConditionComposer
                            key={`${index}-condition`}
                            ref={refs[`${index}-condition`]}
                            onConditionChange={(updatedCondition) => {
                                onTargetingRuleUpdate(updatedCondition, index);
                            }}
                            showConjunctionType={props?.conditionGroup?.conditions?.length > 1 && index !== 0}
                            conjunctionJoinType={props?.conditionGroup?.joinType}
                            condition={expresion}
                            onAddCondition={() => addCondition(index + 1)}
                            onRemoveCondition={() => removeCondition(index)}
                        />
                    );
                })}
            </div>
        );
    }
);
