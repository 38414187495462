import { Checkbox } from 'office-ui-fabric-react';
import * as React from 'react';
import { useEffect, useState } from 'react';

export const LevelTwoItem = (props: { source: ChildItem; onChange?: (modifiedChild: ChildItem) => void }) => {
    const { source } = props;
    const [isSelected, setIsSelected] = useState(source?.selected);

    useEffect(() => {
        setIsSelected(source?.selected);
    }, [source?.selected]);

    return (
        <Checkbox
            id={`${source.parentId}-${source.id}`}
            value={source.id}
            label={source.name}
            checked={isSelected}
            onChange={(_, checked) => {
                setIsSelected(checked);
                props?.onChange({ ...source, selected: checked });
            }}
            key={`${source.parentId}-${source.id}`}
        />
    );
};
