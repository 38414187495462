import { IMentoringTopicsModel } from '../../../Shared/Api/sdk.types';
import { IProgramCard } from './ProgramsList.types';

export enum ProgramActionType {
    FETCH_MY_PROGRAMS = 'FETCH_MY_PROGRAMS',
    FETCH_MY_PROGRAM_SUCCESS = 'FETCH_MY_PROGRAM_SUCCESS',
    FETCH_MY_PROGRAMS_FAILED = 'FETCH_MY_PROGRAMS_FAILED',
}

export enum MentoringTopicsActionType {
    FETCH_MENTORING_TOPICS = 'FETCH_MENTORING_TOPICS',
    FETCH_MENTORING_TOPICS_SUCCESS = 'FETCH_MENTORING_TOPICS_SUCCESS',
    FETCH_MENTORING_TOPICS_FAILED = 'FETCH_MENTORING_TOPICS_FAILED',
}

export interface IFetchMyProgramsAction {
    type: string;
}

export interface IFetchMyProgramsSuccess {
    type: string;
    payload: IProgramCard[];
}

export interface IFetchMyProgramsFailed {
    type: string;
    errorMessage: string;
}

export interface IFetchMyMentoringTopics {
    type: string;
}
export interface IFetchMyMentoringTopicsSuccess {
    type: string;
    payload: IMentoringTopicsModel;
}

export interface IFetchMyMentoringTopicsFailed {
    type: string;
    errorMessage: string;
}

export function fetchMyPrograms(): IFetchMyProgramsAction {
    return {
        type: ProgramActionType.FETCH_MY_PROGRAMS,
    };
}

export function fetchMyProgramsSuccess(programs: IProgramCard[]): IFetchMyProgramsSuccess {
    return {
        type: ProgramActionType.FETCH_MY_PROGRAM_SUCCESS,
        payload: programs,
    };
}

export function fetchMyProgramsFailed(errorMessage: string): IFetchMyProgramsFailed {
    return {
        type: ProgramActionType.FETCH_MY_PROGRAMS_FAILED,
        errorMessage,
    };
}

export function fetchMyMentoringTopics(): IFetchMyMentoringTopics {
    return {
        type: MentoringTopicsActionType.FETCH_MENTORING_TOPICS,
    };
}

export function fetchMyMentoringTopicsSuccess(MentoringTopics: IMentoringTopicsModel): IFetchMyMentoringTopicsSuccess {
    return {
        type: MentoringTopicsActionType.FETCH_MENTORING_TOPICS_SUCCESS,
        payload: MentoringTopics,
    };
}

export function fetchMyMentoringTopicsFailed(errorMessage: string): IFetchMyMentoringTopicsFailed {
    return {
        type: MentoringTopicsActionType.FETCH_MENTORING_TOPICS_FAILED,
        errorMessage,
    };
}
