import { useState, useEffect } from 'react';
import { IProgramState } from '../../Pages/Admin/ProgramTracker/ProgramTracker.types';
import { ProgramStateSequenceLookUp } from '../../Pages/Admin/resources';
import { FeatureNames } from '../Resources/FeatureNames';
import { useIsUserInFeature } from './useIsUserInFeature';

export const useProgramStateSequenceLookUp = () => {
    const isUserInAdminCommunicationsFeature = useIsUserInFeature(FeatureNames.ADMINCOMMUNICATIONS);
    const communicationFilteredLookUp = ProgramStateSequenceLookUp.filter(
        (state) => state !== IProgramState.Communications
    );
    const [programsStepSequenceLookUpInState, setProgramsStepSequenceLookUpInState] = useState(
        communicationFilteredLookUp
    );
    useEffect(() => {
        setProgramsStepSequenceLookUpInState(
            isUserInAdminCommunicationsFeature === true ? ProgramStateSequenceLookUp : communicationFilteredLookUp
        );
    }, [isUserInAdminCommunicationsFeature]);
    return programsStepSequenceLookUpInState;
};
