import {
    IAutoExceptionTelemetry,
    IDependencyTelemetry,
    IEventTelemetry,
    IMetricTelemetry,
    IPageViewPerformanceTelemetry,
    IPageViewTelemetry,
    ITelemetryItem,
    ITraceTelemetry,
    SeverityLevel,
    ApplicationInsights,
    IConfiguration,
    IConfig,
} from '@microsoft/applicationinsights-web';
import { ActionType, ComponentType, EventName } from './TelemetryEnums';
import {
    CustomProperties,
    ITelemetryClient,
    ITelemetryContext,
    TelemetryConfig,
    UsageEvent,
} from '@employee-experience/common';
import { ICookieMgr } from '@microsoft/applicationinsights-core-js';
import { ActionName } from '../Resources/TelemetryEvents';
import { IEnvironmentValues } from '../Models/IEnvironmentValues';

export class IAppInsightsConfig {
    environmentName: string;
    iKey: string;
    serviceOffering: string;
    serviceLine: string;
    service: string;
    componentName: string;
    componentId: string;
}

export class AppInsightsClient implements ITelemetryClient {
    private readonly applicationInsights: ApplicationInsights;
    private readonly environmentValues: IEnvironmentValues;
    private anonymizedUserId: string;

    constructor(appInsightsSettings: IAppInsightsConfig) {
        var aiConfig: IConfiguration & IConfig = {
            instrumentationKey: appInsightsSettings.iKey,
            autoTrackPageVisitTime: true,
            enableAutoRouteTracking: true,
            enableRequestHeaderTracking: true,
            enableCorsCorrelation: true,
            disableCorrelationHeaders: false,
            maxAjaxCallsPerView: -1,
            isCookieUseDisabled: true,
            disableFetchTracking: false,
            disableAjaxTracking: false,
        };
        this.applicationInsights = new ApplicationInsights({ config: aiConfig });

        this.applicationInsights.loadAppInsights();
        this.applicationInsights.context.session.id;

        // envDataInitializer
        this.environmentValues = {
            environmentName: appInsightsSettings.environmentName,
            iKey: appInsightsSettings.iKey,
            serviceOffering: appInsightsSettings.serviceOffering,
            serviceLine: appInsightsSettings.serviceLine,
            service: appInsightsSettings.service,
            componentId: appInsightsSettings.componentId,
            componentName: appInsightsSettings.componentName,
        };

        var environmentDataInitializer = (envelope: ITelemetryItem) => {
            try {
                if (envelope && envelope.baseData) {
                    envelope.baseData.properties = envelope.baseData.properties || {};
                    envelope.baseData.properties['EnvironmentName'] = this.environmentValues.environmentName;
                    envelope.baseData.properties['IKey'] = this.environmentValues.iKey;
                    envelope.baseData.properties['ServiceOffering'] = this.environmentValues.serviceOffering;
                    envelope.baseData.properties['ServiceLine'] = this.environmentValues.serviceLine;
                    envelope.baseData.properties['Service'] = this.environmentValues.service;
                    envelope.baseData.properties['ComponentName'] = this.environmentValues.componentName;
                    envelope.baseData.properties['ComponentId'] = this.environmentValues.componentId;
                }
            } catch (ex) {}
        };
        this.applicationInsights.addTelemetryInitializer(environmentDataInitializer);

        // UserIdAnonymizer
        var anonymizeUserInitializer = (envelope: ITelemetryItem) => {
            try {
                if (envelope && envelope.baseData) {
                    envelope.baseData.properties = envelope.baseData.properties || {};
                    envelope.baseData.properties['UsageUserId'] = this.anonymizedUserId;
                }
            } catch (ex) {
                this.logError(ex, {});
            }
        };
        this.applicationInsights.addTelemetryInitializer(anonymizeUserInitializer);
    }

    public setUserId(anonymousUserId: string) {
        this.anonymizedUserId = anonymousUserId;
    }

    public logInfo(message: string, properties: {}) {
        this.applicationInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Information,
            properties: properties,
        });
    }

    public logWarning = (message: string, properties: {}) => {
        this.applicationInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Warning,
            properties: properties,
        });
    };

    public logError = (message: string, properties: {}) => {
        this.applicationInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Error,
            properties: properties,
        });
    };

    public logEvent = (eventName: string, properties: {} = {}, measurements: any = {}) => {
        this.applicationInsights.trackEvent({
            name: eventName,
            properties: properties,
            measurements: measurements,
        });
    };

    /**
     * Logs Button Clicks as Usage Events
     * @param featureName
     * @param subFeatureName
     */
    public logButtonClick = (featureName: string, subFeatureName: string, properties: any) => {
        var features = properties || {};
        features['FeatureName'] = featureName;
        features['SubFeatureName'] = subFeatureName;
        features['ActionType'] = 'User';
        features['ActionName'] = 'Click';
        features['EventName'] = 'ButtonClick';
        features['ComponentType'] = 'ComponentTypeWeb';
        this.applicationInsights.trackEvent({
            name: featureName,
            properties: features,
        });
    };

    /**
     * Logs Links Clicks as Usage Events
     * @param featureName
     * @param subFeatureName
     */
    public logLinkClick = (featureName: string, subFeatureName: string, properties: any) => {
        var features = properties || {};
        features['FeatureName'] = featureName;
        features['SubFeatureName'] = subFeatureName;
        features['ActionType'] = 'User';
        features['ActionName'] = 'Click';
        features['EventName'] = 'LinkClick';
        features['ComponentType'] = 'ComponentTypeWeb';
        this.applicationInsights.trackEvent({
            name: featureName,
            properties: features,
        });
    };

    /**
     * Logs CheckBox Change as Usage Event
     * @param featureName
     * @param subFeatureName
     */
    public logCheckBoxChange = (featureName: string, subFeatureName: string, ischecked: boolean = true) => {
        var actionName = ischecked == true ? ActionName.Check : ActionName.Uncheck;
        var features: any = {};
        features['FeatureName'] = featureName;
        features['SubFeatureName'] = subFeatureName;
        features['ActionType'] = 'User';
        features['ActionName'] = actionName;
        features['EventName'] = 'CheckboxChange';
        features['ComponentType'] = 'ComponentTypeWeb';
        this.applicationInsights.trackEvent({
            name: featureName,
            properties: features,
        });
    };

    /**
     * Logs Dropdown selected as Usage Event
     * @param featureName
     * @param subFeatureName
     * @param properties
     */
    public logDropDownSelected = (featureName: string, subFeatureName: string, properties: any = {}) => {
        properties['FeatureName'] = featureName;
        properties['SubFeatureName'] = subFeatureName;
        properties['ActionType'] = 'User';
        properties['ActionName'] = 'DropDownSelect';
        properties['EventName'] = 'DropDownSelected';
        properties['ComponentType'] = 'ComponentTypeWeb';
        this.applicationInsights.trackEvent({
            name: featureName,
            properties: properties,
        });
    };

    /**
     * Log feature usage event to see feature level HEART metrics
     * @param featureName
     * @param subFeatureName
     * @param eventName
     * @param actionName
     * @param actionType
     * @param componentType
     * @param properties
     * @param measurements
     */
    public logFeatureUsageEvent = (
        featureName: string,
        subFeatureName: string,
        eventName: EventName,
        actionName: ActionName,
        actionType: ActionType = ActionType.User,
        componentType: ComponentType = ComponentType.Web,
        properties: any = {},
        measurements: any = {}
    ) => {
        properties['FeatureName'] = featureName;
        properties['SubFeatureName'] = subFeatureName;
        properties['ActionType'] = actionType;
        properties['ActionName'] = actionName;
        properties['EventName'] = eventName;
        properties['ComponentType'] = componentType;
        this.applicationInsights.trackEvent({
            name: featureName,
            properties: properties,
            measurements: measurements,
        });
    };

    /**
     * @deprecated Deprecated. Do NOT use for mentoring logging
     * @param dependency
     */
    trackDependencyData(dependency: IDependencyTelemetry): void {
        throw new Error('Method not implemented.');
    }

    /**
     * @deprecated Deprecated. Do NOT use for mentoring logging
     * @param config
     * @param correlationId
     * @param telemetryContext
     */
    getChildInstance(
        config?: TelemetryConfig,
        correlationId?: string,
        telemetryContext?: ITelemetryContext
    ): ITelemetryClient {
        throw new Error('Method not implemented.');
    }

    /**
     * @deprecated Deprecated. Do NOT use for mentoring logging
     * @param context
     */
    setContext(context: ITelemetryContext): void {}

    /**
     * @deprecated Deprecated. Do NOT use for mentoring logging
     * @param authenticatedUserId
     * @param accountId
     * @param storeInCookie
     */
    setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {}

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param pageView
     */
    trackPageView(pageView?: IPageViewTelemetry): void {
        this.applicationInsights.trackPageView(pageView);
    }

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param name
     */
    startTrackEvent(name: string): unknown;

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param usageEvent
     */
    startTrackEvent(usageEvent: UsageEvent): unknown;

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param usageEvent
     * @returns
     */
    startTrackEvent(usageEvent: any): unknown {
        this.applicationInsights.startTrackEvent();
        return usageEvent;
    }

    /**
     * @deprecated Do NOT user for mentoring logging
     * @param event
     * @param customProperties
     */
    trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: unknown }): unknown;

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param event
     * @param customProperties
     */
    trackEvent(event: IEventTelemetry & UsageEvent, customProperties?: CustomProperties): unknown;

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param event
     * @param customProperties
     */
    trackEvent(event: UsageEvent, customProperties?: { [key: string]: unknown }): unknown;

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param event
     * @param customProperties
     * @returns
     */
    trackEvent(event: any, customProperties?: any): unknown {
        this.applicationInsights.trackEvent(event, customProperties);
        return event;
    }

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param telemetryInitializer
     */
    addTelemetryInitializer(telemetryInitializer: (item: ITelemetryItem) => boolean | void): void {}

    /**
     * @deprecated Do NOT use for mentoring logging
     */
    getCookieMgr(): ICookieMgr {
        throw new Error('Method not implemented.');
    }

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param exception
     */
    _onerror(exception: IAutoExceptionTelemetry): void {}

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param trace
     * @param customProperties
     */
    trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: any }): void {
        this.applicationInsights.trackTrace({
            message: trace.message,
            severityLevel: trace.severityLevel,
            properties: [trace.properties, customProperties],
        });
    }

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param metric
     * @param customProperties
     */
    trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: any }): void {
        this.applicationInsights.trackEvent({
            name: metric.name,
            properties: metric.properties,
            measurements: customProperties,
        });
    }

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param name
     */
    startTrackPage(name?: string): void {}

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param name
     * @param url
     * @param customProperties
     */
    stopTrackPage(name?: string, url?: string, customProperties?: Object): void {}

    /**
     * @deprecated Do NOT use for mentoring logging
     * @param pageViewPerformance
     * @param customProperties
     */
    trackPageViewPerformance(
        pageViewPerformance: IPageViewPerformanceTelemetry,
        customProperties?: { [key: string]: any }
    ): void {}
}
